
import { Options, Vue } from 'vue-class-component'

import authService from '@/auth'
import validate from '@/validate'
import { success, warning, error } from '@/Toasts'

import contributionService from '@/services/ContributionService'
import ImagesolError from '@/model/ImagesolError'

import Icon from '@/components/Icon.vue'
import Tag from '@/components/Tag.vue'
import FormSection from '@/components/FormSection.vue'
import FormGroup from '@/components/FormGroup.vue'
import ContributeSteps from '@/components/ContributeSteps.vue'
import ContributeSkeleton from '@/components/ContributeSkeleton.vue'

import TimeSeriesUpdateConflict from '@/components/TimeSeriesUpdateConflict.vue'

import { ImageMetadata, HorizonType, ImageSampleInstructiveContext, TimeSeriesUpdateMethod, LandCoverType, SoilType, Practice } from '@/model/BackendDTOs'

import * as yup from 'yup';
import { BaseSchema } from 'yup';

@Options({
  props: {
    id: String
  },
  components: {
    ContributeSteps,
    ContributeSkeleton,
    FormSection,
    FormGroup,
    Icon,
    Tag,
    TimeSeriesUpdateConflict
  }
})
export default class Contribute3Sample extends Vue {

  id:string;

  horizonTypes:HorizonType[] = ['H', 'O', 'A', 'E', 'B', 'C', 'R'];
  landCoverTypes:LandCoverType[] = [
    'URBAN_FABRIC',
    'INDUSTRIAL_COMMERCIAL_AND_TRANSPORT_UNITS',
    'MINE_DUMP_AND_CONSTRUCTION_SITES',
    'ARTIFICIAL_NON_AGRICULTURAL_VEGETATED_AREAS',
    'ARABLE_LAND',
    'PERMANENT_CROPS',
    'PASTURES',
    'HETEROGENEOUS_AGRICULTURAL_AREAS',
    'FORESTS',
    'SCRUB_AND_OR_HERBACEOUS_VEGETATION_ASSOCIATIONS',
    'OPEN_SPACE_WITH_LITTLE_OR_NO_VEGETATION',
    'INLAND_WETLANDS',
    'MARITIME_WETLANDS',
    'INLAND_WATERS',
    'MARINE_WATERS'
  ];

  soilTypes:SoilType[] = ['Acrisols',
    'Alisols',
    'Andosols',
    'Anthrosols',
    'Arenosols',
    'Calcisols',
    'Cambisols',
    'Chernozems',
    'Cryosols',
    'Durisols',
    'Ferralsols',
    'Fluvisols',
    'Gleysols',
    'Gypsisols',
    'Histosols',
    'Kastanozems',
    'Leptosols',
    'Lixisols',
    'Luvisols',
    'Nitisols',
    'Phaeozems',
    'Planosols',
    'Plinthosols',
    'Podzols',
    'Solonchaks',
    'Solonetz',
    'Stagnosols',
    'Technosols',
    'Umbrisols',
    'Vertisols'
  ];

  practices:Practice[] = ['PLOUGHING', 'SUPERFICIAL', 'NONE', 'OTHER'];

  naturalType = false

  authenticated = false
  savePending = false

  errors:{ [index: string]: string } = {};

  metadata:ImageMetadata|null = null;
  model2:ImageSampleInstructiveContext|null = null;

  timeSeriesConflictModalActive = false;
  timeSeriesConflictFields:string[]|null = null;

  validations2:{ [index: string]: BaseSchema } = {
  };

  validationsNatural:{ [index: string]: BaseSchema } = {
    samplingDepthBottom: yup.number().required('mandatory'),
    samplingDepthTop: yup.number().required('mandatory'),
    landCover: yup.mixed<LandCoverType>().required('mandatory'),
    culture: yup.string().required('mandatory'),
    horizonType: yup.string().required('mandatory'),
    soilTillage: yup.string().required('mandatory'),
    lastTillage: yup.date().required('mandatory'),
    fertilizationType: yup.string().required('mandatory'),
    lastFertilization: yup.date().required('mandatory')
  };

  created() {
    authService.getUser().then(u => {
      if (u) {
        this.authenticated = true
        contributionService.loadMetadata(this.id)
          .then(
            (data:ImageMetadata) => {
              this.metadata = data;
              this.model2 = this.metadata.imageSampleInstructiveContext;
              this.naturalType = data.imageSamplesInfo.type === 'NATURAL'
            },
            (e:Error) => {
              error(this.$oruga, e.message)
            }
          )

      } else {
        warning(this.$oruga, this.$t('contribute.step1.notAuthenticated'))
        this.$router.push('/')
      }
    });
  }

  cancel() {
    if (this.metadata?.compliant) {
      if (window.confirm(this.$t('contribute.confirmCancel'))) {
        this.$router.push('/')
      }
    } else {
      if (window.confirm(this.$t('contribute.confirmCancelAndDelete'))) {
        contributionService.deleteMetadata(this.id)
          .then(
            () => {
              success(this.$oruga, this.$t('contribute.dropped'));
              this.$router.push('/')
            },
            (e:Error) => {
              error(this.$oruga, "Impossible de supprimer votre contribution: " + e.message)
            }
          )
      }
    }
  }

  previous() {
    this.$router.push(`/contribute/${this.id}/sample`)
  }

  saveModel2() {
    this.save0();
  }

  saveOnlyContribution() {
    this.timeSeriesConflictModalActive = false;
    this.save0('CONTRIBUTION_ONLY');
  }

  saveSpreadToTimeSeries() {
    this.timeSeriesConflictModalActive = false;
    this.save0('TIME_SERIES');
  }

  getTopAsAny():any {
    return this.model2?.samplingDepthTop;
  }

  getBottomAsAny():any {
    return this.model2?.samplingDepthBottom;
  }

  save0(tsum?:TimeSeriesUpdateMethod) {
    let validations;
    if (this.naturalType) {
      validations = { ...this.validations2, ...this.validationsNatural }
    } else {
      validations = { ...this.validations2 }
    }
    this.errors = validate(this.model2, validations, this.$t);
    if (this.model2?.samplingDepthBottom && this.model2?.samplingDepthTop) {
      // Pour une raison inconnue, il arrive que les nombres entiers soient en 'string', auquel cas la comparaison ne fonctionne pas.
      // On fait en sorte que ça n'arrive pas en les parsant si c'est une 'string'
      const topValue:number = ((typeof this.model2?.samplingDepthTop) === 'string') ? parseInt(this.getTopAsAny()) : this.model2?.samplingDepthTop;
      const bottomValue:number = ((typeof this.model2?.samplingDepthBottom) === 'string') ? parseInt(this.getBottomAsAny()) : this.model2?.samplingDepthBottom;
      if (topValue > bottomValue) {
        this.errors.samplingDepthTop = this.$t('forms.errors.incoherent');
        this.errors.samplingDepthBottom = this.$t('forms.errors.incoherent');
      }
    }
    if (Object.keys(this.errors).length === 0) {
      this.savePending = true;

      contributionService.saveImageSampleInstructiveContext(this.id, this.model2!, tsum)
        .then(
          () => {
            this.savePending = false;
            this.$router.push(`/contribute/${this.id}/administrative`)
          },
          (e:ImagesolError) => {
            this.savePending = false;
            if (e.type && e.type === 'TimeSeriesUpdateDetectedException') {
              this.timeSeriesConflictFields = e.tsuFields!;
              this.timeSeriesConflictModalActive = true;
            } else {
              error(this.$oruga, "Une erreur est survenue pendant la mise à jour de la contribution : " + e.type)
            }
          }
        )
    } else {
      document.getElementById("formTop")?.scrollIntoView({
        behavior: "smooth"
      })
    }
  }

}
