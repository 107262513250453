import { BaseSchema } from 'yup';
export declare type Path = string;

export default (model:any, validations:{ [index: string]: BaseSchema }, t:(key: Path) => string):{ [index: string]: string } => {

  const result:{ [index: string]: string } = {};
  Object.keys(validations).forEach(name => {
    const value = model[name];
    const schema:BaseSchema = validations[name];

    try {
      schema.validateSync(value, { abortEarly: false });
    } catch (e:any) {
      result[name] = e.errors.map((str:string) => t('forms.errors.' + str)).join(' ; ');
    }
  });

  return result;
}
