
import { Options, Vue } from 'vue-class-component'

import Icon from '@/components/Icon.vue'

@Options({
  props: {
    text: String,
    closable: Boolean
  },
  components: {
    Icon
  }
})
export default class Tag extends Vue {
}
