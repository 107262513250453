import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "contribute" }
const _hoisted_2 = { class: "page-header big" }
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = {
  key: 1,
  class: "columns",
  id: "formTop"
}
const _hoisted_5 = { class: "column" }
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "block" }
const _hoisted_9 = { class: "column" }
const _hoisted_10 = ["value"]
const _hoisted_11 = ["value"]
const _hoisted_12 = {
  key: 2,
  class: "contribute-buttons"
}
const _hoisted_13 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ContributeSteps = _resolveComponent("ContributeSteps")!
  const _component_ContributeSkeleton = _resolveComponent("ContributeSkeleton")!
  const _component_o_select = _resolveComponent("o-select")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_o_input = _resolveComponent("o-input")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_o_radio = _resolveComponent("o-radio")!
  const _component_FormSection = _resolveComponent("FormSection")!
  const _component_o_datepicker = _resolveComponent("o-datepicker")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TimeSeriesUpdateConflict = _resolveComponent("TimeSeriesUpdateConflict")!
  const _component_o_modal = _resolveComponent("o-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.metadata)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.metadata.compliant ? _ctx.$t("contribute.updateTitle") : _ctx.$t("contribute.title")), 1))
        : _createCommentVNode("", true),
      (_ctx.metadata)
        ? (_openBlock(), _createBlock(_component_Tag, {
            key: 1,
            text: _ctx.metadata.imageBootstrap.summary
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ContributeSteps, {
      step: 4,
      id: _ctx.id,
      compliant: _ctx.metadata?.compliant
    }, null, 8, ["id", "compliant"]),
    (!_ctx.model2)
      ? (_openBlock(), _createBlock(_component_ContributeSkeleton, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.model2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_FormSection, {
              class: "column",
              title: "contribute.step4.pedologicContextSection"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FormGroup, { label: "contribute.step4.pedologicData" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.soilType'),
                      variant: _ctx.errors.soilType ? 'danger':'',
                      message: _ctx.errors.soilType
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_select, {
                          modelValue: _ctx.model2.soilType,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model2.soilType) = $event)),
                          expanded: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.soilTypes, (s) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: s,
                                value: s
                              }, _toDisplayString(_ctx.$t(s)), 9, _hoisted_6))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.soilTypeOther'),
                      variant: _ctx.errors.soilTypeOther ? 'danger':'',
                      message: _ctx.errors.soilTypeOther
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model2.soilTypeOther,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model2.soilTypeOther) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.soilTypeOtherClassificationSystem'),
                      variant: _ctx.errors.soilTypeOtherClassificationSystem ? 'danger':'',
                      message: _ctx.errors.soilTypeOtherClassificationSystem
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model2.soilTypeOtherClassificationSystem,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model2.soilTypeOtherClassificationSystem) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.parentMaterial'),
                      variant: _ctx.errors.parentMaterial ? 'danger':'',
                      message: _ctx.errors.parentMaterial
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model2.parentMaterial,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model2.parentMaterial) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormGroup, { label: "contribute.step4.pedologicSampling" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      grouped: "",
                      label: _ctx.$t('contribute.step4.samplingDepth'),
                      class: _normalizeClass(_ctx.naturalType ? ('mandatory_' + _ctx.$i18n.locale) : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_field, {
                          label: _ctx.$t('contribute.step4.samplingDepthTop'),
                          horizontal: "",
                          variant: _ctx.errors.samplingDepthTop ? 'danger':'',
                          message: _ctx.errors.samplingDepthTop,
                          class: _normalizeClass(_ctx.naturalType ? ('mandatory_' + _ctx.$i18n.locale) : '')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_input, {
                              modelValue: _ctx.model2.samplingDepthTop,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model2.samplingDepthTop) = $event)),
                              type: "number"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message", "class"]),
                        _createVNode(_component_o_field, {
                          label: _ctx.$t('contribute.step4.samplingDepthBottom'),
                          horizontal: "",
                          variant: _ctx.errors.samplingDepthBottom ? 'danger':'',
                          message: _ctx.errors.samplingDepthBottom,
                          class: _normalizeClass(_ctx.naturalType ? ('mandatory_' + _ctx.$i18n.locale) : '')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_input, {
                              modelValue: _ctx.model2.samplingDepthBottom,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model2.samplingDepthBottom) = $event)),
                              type: "number"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message", "class"])
                      ]),
                      _: 1
                    }, 8, ["label", "class"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.horizonType'),
                      variant: _ctx.errors.horizonType ? 'danger':'',
                      message: _ctx.errors.horizonType,
                      class: _normalizeClass(_ctx.naturalType ? ('mandatory_' + _ctx.$i18n.locale) : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_select, {
                          modelValue: _ctx.model2.horizonType,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model2.horizonType) = $event)),
                          expanded: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.horizonTypes, (d) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: d,
                                value: d
                              }, _toDisplayString(_ctx.$t('enums.HorizonType.' + d)), 9, _hoisted_7))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.soilTillageHorizon'),
                      variant: _ctx.errors.soilTillageHorizon ? 'danger':'',
                      message: _ctx.errors.soilTillageHorizon
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_o_radio, {
                            modelValue: _ctx.model2.soilTillageHorizon,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model2.soilTillageHorizon) = $event)),
                            "native-value": true
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('forms.boolean_true')), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _createVNode(_component_o_radio, {
                            modelValue: _ctx.model2.soilTillageHorizon,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model2.soilTillageHorizon) = $event)),
                            "native-value": false
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('forms.boolean_false')), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormGroup, { label: "contribute.step4.pedologicTextures" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      grouped: "",
                      label: _ctx.$t('contribute.step4.textures')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_field, {
                          label: _ctx.$t('contribute.step4.textureClayPercent'),
                          horizontal: "",
                          variant: _ctx.errors.textureClayPercent ? 'danger':'',
                          message: _ctx.errors.textureClayPercent
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_input, {
                              modelValue: _ctx.model2.textureClayPercent,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model2.textureClayPercent) = $event)),
                              type: "number"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message"]),
                        _createVNode(_component_o_field, {
                          label: _ctx.$t('contribute.step4.textureSiltPercent'),
                          horizontal: "",
                          variant: _ctx.errors.textureSiltPercent ? 'danger':'',
                          message: _ctx.errors.textureSiltPercent
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_input, {
                              modelValue: _ctx.model2.textureSiltPercent,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model2.textureSiltPercent) = $event)),
                              type: "number"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message"]),
                        _createVNode(_component_o_field, {
                          label: _ctx.$t('contribute.step4.textureSandPercent'),
                          horizontal: "",
                          variant: _ctx.errors.textureSandPercent ? 'danger':'',
                          message: _ctx.errors.textureSandPercent
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_input, {
                              modelValue: _ctx.model2.textureSandPercent,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model2.textureSandPercent) = $event)),
                              type: "number"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.textureCarbonPercent'),
                      horizontal: "",
                      variant: _ctx.errors.textureCarbonPercent ? 'danger':'',
                      message: _ctx.errors.textureCarbonPercent
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model2.textureCarbonPercent,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model2.textureCarbonPercent) = $event)),
                          type: "number"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.texturePh'),
                      horizontal: "",
                      variant: _ctx.errors.texturePh ? 'danger':'',
                      message: _ctx.errors.texturePh
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model2.texturePh,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model2.texturePh) = $event)),
                          type: "number"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormGroup, { mandatory: false }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.pedologicalComments'),
                      variant: _ctx.errors.pedologicalComments ? 'danger':'',
                      message: _ctx.errors.pedologicalComments
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          type: "textarea",
                          modelValue: _ctx.model2.pedologicalComments,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model2.pedologicalComments) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_FormSection, {
              class: "column",
              title: "contribute.step4.agronomicContextSection"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FormGroup, { label: "contribute.step4.agronomicData" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.landCover'),
                      variant: _ctx.errors.landCover ? 'danger':'',
                      message: _ctx.errors.landCover,
                      class: _normalizeClass(_ctx.naturalType ? ('mandatory_' + _ctx.$i18n.locale) : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_select, {
                          modelValue: _ctx.model2.landCover,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model2.landCover) = $event)),
                          expanded: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.landCoverTypes, (d) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: d,
                                value: d
                              }, _toDisplayString(_ctx.$t('enums.LandCoverType.' + d)), 9, _hoisted_10))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.culture'),
                      variant: _ctx.errors.culture ? 'danger':'',
                      message: _ctx.errors.culture,
                      class: _normalizeClass(_ctx.naturalType ? ('mandatory_' + _ctx.$i18n.locale) : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model2.culture,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model2.culture) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.soilTillage'),
                      variant: _ctx.errors.soilTillage ? 'danger':'',
                      message: _ctx.errors.soilTillage,
                      class: _normalizeClass(_ctx.naturalType ? ('mandatory_' + _ctx.$i18n.locale) : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model2.soilTillage,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model2.soilTillage) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.lastTillage'),
                      variant: _ctx.errors.lastTillage ? 'danger':'',
                      message: _ctx.errors.lastTillage,
                      class: _normalizeClass(_ctx.naturalType ? ('mandatory_' + _ctx.$i18n.locale) : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_datepicker, {
                          modelValue: _ctx.model2.lastTillage,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model2.lastTillage) = $event)),
                          locale: _ctx.$i18n.locale,
                          showWeekNumber: false
                        }, null, 8, ["modelValue", "locale"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.fertilizationType'),
                      variant: _ctx.errors.fertilizationType ? 'danger':'',
                      message: _ctx.errors.fertilizationType,
                      class: _normalizeClass(_ctx.naturalType ? ('mandatory_' + _ctx.$i18n.locale) : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model2.fertilizationType,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.model2.fertilizationType) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.lastFertilization'),
                      variant: _ctx.errors.lastFertilization ? 'danger':'',
                      message: _ctx.errors.lastFertilization,
                      class: _normalizeClass(_ctx.naturalType ? ('mandatory_' + _ctx.$i18n.locale) : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_datepicker, {
                          modelValue: _ctx.model2.lastFertilization,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.model2.lastFertilization) = $event)),
                          locale: _ctx.$i18n.locale,
                          showWeekNumber: false
                        }, null, 8, ["modelValue", "locale"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.practice'),
                      variant: _ctx.errors.practice ? 'danger':'',
                      message: _ctx.errors.practice
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_select, {
                          modelValue: _ctx.model2.practice,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.model2.practice) = $event)),
                          expanded: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.practices, (d) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: d,
                                value: d
                              }, _toDisplayString(_ctx.$t('enums.Practice.' + d)), 9, _hoisted_11))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"]),
                    (_ctx.model2.practice === 'PLOUGHING' || _ctx.model2.practice === 'SUPERFICIAL')
                      ? (_openBlock(), _createBlock(_component_o_field, {
                          key: 0,
                          label: _ctx.$t('contribute.step4.practiceDepth'),
                          horizontal: "",
                          variant: _ctx.errors.practiceDepth ? 'danger':'',
                          message: _ctx.errors.practiceDepth
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_input, {
                              modelValue: _ctx.model2.practiceDepth,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.model2.practiceDepth) = $event)),
                              type: "number"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormGroup, { mandatory: false }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step4.agronomicComments'),
                      variant: _ctx.errors.agronomicComments ? 'danger':'',
                      message: _ctx.errors.agronomicComments
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          type: "textarea",
                          modelValue: _ctx.model2.agronomicComments,
                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.model2.agronomicComments) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.model2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_o_button, {
            onClick: _ctx.cancel,
            inverted: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('forms.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_o_button, {
            onClick: _ctx.previous,
            outlined: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('forms.previous')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_o_button, {
            onClick: _ctx.saveModel2,
            disabled: !_ctx.authenticated || _ctx.savePending
          }, {
            default: _withCtx(() => [
              (!_ctx.savePending)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('forms.next')), 1))
                : _createCommentVNode("", true),
              (_ctx.savePending)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 1,
                    name: "refresh",
                    rotate: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_o_modal, {
      active: _ctx.timeSeriesConflictModalActive,
      "onUpdate:active": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.timeSeriesConflictModalActive) = $event)),
      scroll: "clip"
    }, {
      default: _withCtx(() => [
        (_ctx.timeSeriesConflictModalActive)
          ? (_openBlock(), _createBlock(_component_TimeSeriesUpdateConflict, {
              key: 0,
              fields: _ctx.timeSeriesConflictFields,
              prefix: 'contribute.step4',
              onCancel: _cache[24] || (_cache[24] = ($event: any) => (_ctx.timeSeriesConflictModalActive = false)),
              onOnlyContribution: _ctx.saveOnlyContribution,
              onSpreadToTimeSeries: _ctx.saveSpreadToTimeSeries
            }, null, 8, ["fields", "prefix", "onOnlyContribution", "onSpreadToTimeSeries"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}