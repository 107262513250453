import authService from '@/auth'

import config from '@/Config'
const BACKEND = config.getBackend();

export default async (uri: string, params: any = {}):Promise<Response> => {
  let user = await authService.getUser()
  if (user === null) {
    throw new Error('user not logged in')
  }
  if (user.expired) {
    console.warn("Token expiré on en demande un nouveau")
    user = await authService.renewToken()
  }
  const init: RequestInit = { method: 'GET' }
  if (user?.access_token) {
    const headers = new Headers({ Authorization: 'Bearer ' + user!.access_token })
    if (init.headers !== undefined) {
      (init.headers as Headers).forEach((entry) => {
        headers.set(entry[0], entry[1])
      })
    }
    init.headers = headers
  }
  const url = new URL(`${BACKEND}${uri}`)
  if (params) {
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]))
  }
  const result = await fetch(url.toString(), init)
  return result
}
