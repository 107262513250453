import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "metadata-row"
}
const _hoisted_2 = { class: "metadatum" }
const _hoisted_3 = {
  key: 0,
  class: "value"
}
const _hoisted_4 = {
  key: 1,
  class: "value"
}
const _hoisted_5 = {
  key: 2,
  class: "value"
}
const _hoisted_6 = {
  key: 3,
  class: "value"
}
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.value)
    ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
        _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.labelPrefix) + " " + _toDisplayString(_ctx.label), 1),
        (!_ctx.translatePrefix && !_ctx.formatter && !_ctx.url)
          ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(_ctx.value), 1))
          : _createCommentVNode("", true),
        (_ctx.translatePrefix)
          ? (_openBlock(), _createElementBlock("td", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.translatePrefix + _ctx.value)), 1))
          : _createCommentVNode("", true),
        (_ctx.formatter)
          ? (_openBlock(), _createElementBlock("td", _hoisted_5, _toDisplayString(_ctx.formatter(_ctx.value)), 1))
          : _createCommentVNode("", true),
        (_ctx.url)
          ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
              _createElementVNode("a", {
                href: _ctx.value,
                target: "_blank"
              }, _toDisplayString(_ctx.value), 9, _hoisted_7)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}