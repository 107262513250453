
import { Options, Vue } from 'vue-class-component'

import authService from '@/auth'
import validate from '@/validate'
import { success, warning, error } from '@/Toasts'

import contributionService from '@/services/ContributionService'
import regionService from '@/services/RegionService'
import ImagesolError from '@/model/ImagesolError'

import Icon from '@/components/Icon.vue'
import Tooltip from '@/components/Tooltip.vue'
import Tag from '@/components/Tag.vue'
import FormSection from '@/components/FormSection.vue'
import FormGroup from '@/components/FormGroup.vue'
import ContributeSteps from '@/components/ContributeSteps.vue'
import ContributeSkeleton from '@/components/ContributeSkeleton.vue'

import TimeSeriesUpdateConflict from '@/components/TimeSeriesUpdateConflict.vue'

import { ImageMetadata, Shape, SampleType, SamplingGuidance, SamplingRowType, ImageSamplesInfo, TimeSeriesUpdateMethod, Country, Region } from '@/model/BackendDTOs'

import * as yup from 'yup';
import { BaseSchema } from 'yup';

@Options({
  props: {
    id: String
  },
  watch: {
    "model1.region": [
      {
        handler: 'updateCountry'
      }
    ],
    countries: [
      {
        handler: 'updateCountry'
      }
    ],
    country: [
      {
        handler: 'updateRegions'
      }
    ]
  },
  components: {
    ContributeSteps,
    ContributeSkeleton,
    FormSection,
    FormGroup,
    Icon,
    Tag,
    Tooltip,
    TimeSeriesUpdateConflict
  }
})
export default class Contribute3Sample extends Vue {

  id:string;

  sampleTypes:SampleType[] = ["NATURAL", "REPACKED", "RECONSTITUTED"]
  shapes:Shape[] = ["CYLINDER", "CUBE", "AGGREGATE", "OTHER"]
  samplingGuidances:SamplingGuidance[] = ["HORIZONTAL", "VERTICAL", "OTHER"]
  samplingRowTypes:SamplingRowType[] = ["ROW", "INTER_ROW"]
  countries:Country[] = []
  regions: Region[] = []

  authenticated = false
  savePending = false

  errors:{ [index: string]: string } = {};

  metadata:ImageMetadata|null = null;
  model1:ImageSamplesInfo|null = null;

  country: Country|null = null;

  timeSeriesConflictModalActive = false;
  timeSeriesConflictFields:string[]|null = null;

  validations1:{ [index: string]: BaseSchema } = {
    type: yup.string().required('mandatory'),
    shape: yup.string().required('mandatory'),
    sampleSizeX: yup.number().positive().integer().required('mandatory'),
    sampleSizeY: yup.number().positive().integer().required('mandatory')
  };

  validationsNatural:{ [index: string]: BaseSchema } = {
    samplingDateTime: yup.date().required('mandatory'),
    region: yup.object().required('mandatory'),
    samplingGuidance: yup.string().required('mandatory')
  };

  async created() {
    await authService.getUser().then(u => {
      if (u) {
        this.authenticated = true
        contributionService.loadMetadata(this.id)
          .then(
            (data:ImageMetadata) => {
              this.metadata = data;
              this.model1 = this.metadata.imageSamplesInfo;
              console.log("got here")
            },
            (e:Error) => {
              error(this.$oruga, e.message)
            }
          )

      } else {
        warning(this.$oruga, this.$t('contribute.step1.notAuthenticated'))
        this.$router.push('/')
      }
    });

    regionService.loadCountries().then(data => {
      if (data) {
        this.countries = data;
      }
    })
  }

  updateCountry() {
    if (this.model1) {
      this.countries.forEach(country => {
        if (this.model1?.region && country.country_en === this.model1?.region.country_en) {
          this.country = country;
        }
      });
    }
  }

  updateRegions() {
    if (this.country) {
      regionService.loadRegions(this.country.country_en).then(data => {
        if (data) {
          this.regions = data;
        }
      })
    }
  }

  cancel() {
    if (this.metadata?.compliant) {
      if (window.confirm(this.$t('contribute.confirmCancel'))) {
        this.$router.push('/')
      }
    } else {
      if (window.confirm(this.$t('contribute.confirmCancelAndDelete'))) {
        contributionService.deleteMetadata(this.id)
          .then(
            () => {
              success(this.$oruga, this.$t('contribute.dropped'));
              this.$router.push('/')
            },
            (e:Error) => {
              error(this.$oruga, "Impossible de supprimer votre contribution: " + e.message)
            }
          )
      }
    }
  }

  previous() {
    this.$router.push(`/contribute/${this.id}/technical`)
  }

  saveModel1() {
    this.save0();
  }

  saveOnlyContribution() {
    this.timeSeriesConflictModalActive = false;
    this.save0('CONTRIBUTION_ONLY');
  }

  saveSpreadToTimeSeries() {
    this.timeSeriesConflictModalActive = false;
    this.save0('TIME_SERIES');
  }

  save0(tsum?:TimeSeriesUpdateMethod) {
    let validations;
    if (this.model1?.type === 'NATURAL') {
      validations = { ...this.validations1, ...this.validationsNatural }
    } else {
      validations = { ...this.validations1 }
    }
    if (this.model1?.shape !== 'CYLINDER') {
      validations.sampleSizeY = yup.number().positive().integer().required('mandatory');
    }
    this.errors = validate(this.model1, validations, this.$t);
    if (Object.keys(this.errors).length === 0) {
      this.savePending = true;
      contributionService.saveImageSamplesInfo(this.id, this.model1!, tsum)
        .then(
          () => {
            this.savePending = false;
            this.$router.push(`/contribute/${this.id}/instructive`)
          },
          (e:ImagesolError) => {
            this.savePending = false;
            if (e.type && e.type === 'TimeSeriesUpdateDetectedException') {
              this.timeSeriesConflictFields = e.tsuFields!;
              this.timeSeriesConflictModalActive = true;
            } else {
              error(this.$oruga, "Une erreur est survenue pendant la mise à jour de la contribution : " + e.type)
            }
          }
        )
    } else {
      document.getElementById("formTop")?.scrollIntoView({
        behavior: "smooth"
      })
    }
  }

}
