
import { Vue } from 'vue-class-component'

import authService from '@/auth'
import { ImagesolUser } from '@/auth/ImagesolUser'

import fetchPost from '@/fetchPost'

export default class RawLayout extends Vue {

  mounted () {
    authService.loginCallback().then(this.userLoggedIn, console.error)
  }

  userLoggedIn(user:ImagesolUser) {
    // On informe le backend pour qu'il ait les infos up-to-date
    fetchPost(`/v1/imagesol/users/signin-callback`);

    // et on redirige vers la page demandée
    if (user.state?.pathBeforeLogin) {
        this.$router.push(user.state?.pathBeforeLogin);
    }
  }

}
