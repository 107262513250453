
import { Options, Vue } from 'vue-class-component'

import FormSection from '@/components/FormSection.vue'
import FormGroup from '@/components/FormGroup.vue'
import ThreeStateToggle from '@/components/ThreeStateToggle.vue'

import { HorizonType, LandCoverType, SoilType, Practice, SearchForm } from '@/model/BackendDTOs'

@Options({
  props: {
    filters: SearchForm
  },
  components: {
    FormSection,
    FormGroup,
    ThreeStateToggle
  }
})
export default class Contribute3Sample extends Vue {

  filters: SearchForm

  horizonTypes:HorizonType[] = ['H', 'O', 'A', 'E', 'B', 'C', 'R'];
  landCoverTypes:LandCoverType[] = [
    'URBAN_FABRIC',
    'INDUSTRIAL_COMMERCIAL_AND_TRANSPORT_UNITS',
    'MINE_DUMP_AND_CONSTRUCTION_SITES',
    'ARTIFICIAL_NON_AGRICULTURAL_VEGETATED_AREAS',
    'ARABLE_LAND',
    'PERMANENT_CROPS',
    'PASTURES',
    'HETEROGENEOUS_AGRICULTURAL_AREAS',
    'FORESTS',
    'SCRUB_AND_OR_HERBACEOUS_VEGETATION_ASSOCIATIONS',
    'OPEN_SPACE_WITH_LITTLE_OR_NO_VEGETATION',
    'INLAND_WETLANDS',
    'MARITIME_WETLANDS',
    'INLAND_WATERS',
    'MARINE_WATERS'
  ];

  soilTypes:SoilType[] = ['Acrisols',
    'Alisols',
    'Andosols',
    'Anthrosols',
    'Arenosols',
    'Calcisols',
    'Cambisols',
    'Chernozems',
    'Cryosols',
    'Durisols',
    'Ferralsols',
    'Fluvisols',
    'Gleysols',
    'Gypsisols',
    'Histosols',
    'Kastanozems',
    'Leptosols',
    'Lixisols',
    'Luvisols',
    'Nitisols',
    'Phaeozems',
    'Planosols',
    'Plinthosols',
    'Podzols',
    'Solonchaks',
    'Solonetz',
    'Stagnosols',
    'Technosols',
    'Umbrisols',
    'Vertisols'
  ];

  practices:Practice[] = ['PLOUGHING', 'SUPERFICIAL', 'NONE', 'OTHER'];
}
