
import { Options, Vue } from 'vue-class-component'

import regionService from '@/services/RegionService'

import FormSection from '@/components/FormSection.vue'
import FormGroup from '@/components/FormGroup.vue'

import { Shape, SampleType, SamplingGuidance, SamplingRowType, Country, Region, SearchForm } from '@/model/BackendDTOs'

@Options({
  props: {
    filters: SearchForm
  },
  watch: {
    "filters.region": [
      {
        handler: 'updateCountry'
      }
    ],
    countries: [
      {
        handler: 'updateCountry'
      }
    ],
    country: [
      {
        handler: 'updateRegions'
      }
    ]
  },
  components: {
    FormSection,
    FormGroup
  }
})
export default class Contribute3Sample extends Vue {

  filters: SearchForm;

  sampleTypes:SampleType[] = ["NATURAL", "REPACKED", "RECONSTITUTED"]
  shapes:Shape[] = ["CYLINDER", "CUBE", "AGGREGATE", "OTHER"]
  samplingGuidances:SamplingGuidance[] = ["HORIZONTAL", "VERTICAL", "OTHER"]
  samplingRowTypes:SamplingRowType[] = ["ROW", "INTER_ROW"]
  countries:Country[] = []
  regions: Region[] = []

  country: Country|null = null;

  async created() {

    regionService.loadCountries().then(data => {
      if (data) {
        this.countries = data;
      }
    })
  }

  updateCountry() {
    if (this.filters) {
      this.countries.forEach(country => {
        if (this.filters?.region && country.country_en === this.filters?.region.country_en) {
          this.country = country;
        }
      });
    }
  }

  updateRegions() {
    if (this.country) {
      if (this.filters.region && this.country.country_en !== this.filters.region.country_en) {
        this.filters.region = undefined;
      }
      regionService.loadRegions(this.country.country_en).then(data => {
        if (data) {
          this.regions = data;
        }
      })
    }
  }
}
