import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "toggle-container" }
const _hoisted_2 = { class: "tri-state-toggle" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        class: "one button opaque",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle(1))),
        type: "radio",
        name: "toggle",
        ref: "one"
      }, null, 512),
      _createElementVNode("input", {
        class: "two button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggle(2))),
        type: "radio",
        name: "toggle",
        ref: "two"
      }, null, 512),
      _createElementVNode("input", {
        class: "three button",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggle(3))),
        type: "radio",
        name: "toggle",
        ref: "three"
      }, null, 512)
    ]),
    (_ctx.modelValue===true)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Oui"))
      : _createCommentVNode("", true),
    (_ctx.modelValue===undefined || _ctx.modelValue===null)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Indéfini"))
      : _createCommentVNode("", true),
    (_ctx.modelValue===false)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Non"))
      : _createCommentVNode("", true)
  ]))
}