import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import LoginCallback from '@/views/LoginCallback.vue'

import Home from '@/views/Home.vue'
import Search from '@/views/Search.vue'
import Read from '@/views/Read.vue'
import Contribute1Bootstrap from '@/views/Contribute1Bootstrap.vue'
import ContributeZipResult from '@/views/ContributeZipResult.vue'
import Contribute2Technical from '@/views/Contribute2Technical.vue'
import Contribute3Sample from '@/views/Contribute3Sample.vue'
import Contribute4Instructive from '@/views/Contribute4Instructive.vue'
import Contribute5Administrative from '@/views/Contribute5Administrative.vue'
import FAQ from '@/views/FAQ.vue'
import Partners from '@/views/Partners.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      home: true
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ,
    meta: {
      footer: true
    }
  },
  {
    path: '/partners',
    name: 'partners',
    component: Partners,
    meta: {
      footer: true
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      section: 'search'
    }
  },
  {
    path: '/contribute',
    name: 'ContributeNew',
    component: Contribute1Bootstrap,
    meta: {
      section: 'contribute'
    }
  },
  {
    path: '/contribute/:id',
    name: 'Contribute1Bootstrap',
    component: Contribute1Bootstrap,
    meta: {
      section: 'contribute'
    },
    props: true
  },
  {
    path: '/contribute/:id/technical',
    name: 'Contribute2Technical',
    component: Contribute2Technical,
    meta: {
      section: 'contribute'
    },
    props: true
  },
  {
    path: '/contribute/:id/zip-result',
    name: 'ContributeZipResult',
    component: ContributeZipResult,
    meta: {
      section: 'contribute'
    },
    props: true
  },
  {
    path: '/contribute/:id/sample',
    name: 'Contribute3Sample',
    component: Contribute3Sample,
    meta: {
      section: 'contribute'
    },
    props: true
  },
  {
    path: '/contribute/:id/instructive',
    name: 'Contribute4Instructive',
    component: Contribute4Instructive,
    meta: {
      section: 'contribute'
    },
    props: true
  },
  {
    path: '/contribute/:id/administrative',
    name: 'Contribute5Administrative',
    component: Contribute5Administrative,
    meta: {
      section: 'contribute'
    },
    props: true
  },
  {
    path: '/read/:id',
    name: 'Read',
    component: Read,
    meta: {
      section: 'search'
    },
    props: true
  },
  {
    path: '/login-callback',
    name: 'LoginCallback',
    component: LoginCallback,
    meta: {
      layout: 'raw'
    },
    props: true
  }
  // ,
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
