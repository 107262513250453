import fetchGet from '@/fetchGet'

import { Country, Region } from '@/model/BackendDTOs'

export class RegionService {
  loadCountries(): Promise<Country[]> {
    return new Promise<Country[]>((resolve, reject) => {

      fetchGet(`/v1/imagesol/region/countries`)
        .then((res) => {
          if (res.ok) {
            res.json().then((data: Country[]) => {
              resolve(data);
            })
          } else {
            reject(new Error(res.statusText))
          }
        },
        reject);

    });
  }

  loadRegions(countryEn: string): Promise<Region[]> {
    return new Promise<Region[]>((resolve, reject) => {

      fetchGet(`/v1/imagesol/region/` + countryEn)
        .then((res) => {
          if (res.ok) {
            res.json().then((data: Region[]) => {
              resolve(data);
            })
          } else {
            reject(new Error(res.statusText))
          }
        },
        reject);

    });
  }
}

const instance = new RegionService()

export default instance
