/* tslint:disable */
/* eslint-disable */

export class Coordinate {
    coordinateWgs84X: number;
    coordinateWgs84Y: number;
    coordinateWgs84Z: number;
}

export class Country {
    country_en: string;
    country_fr: string;
}

export class CreationResult {
    id: number;
    persistentId: string;
}

export class DatasetVersion {
    createTime: string;
    datasetId: number;
    datasetPersistentId: string;
    fileAccessRequest: boolean;
    files: DataverseFile[];
    id: number;
    lastUpdateTime: string;
    license: string;
    metadataBlocks: MetadataBlocks;
    productionDate: string;
    productionPlace: string;
    releaseTime: string;
    storageIdentifier: string;
    termsOfAccess: string;
    termsOfUse: string;
    versionMinorNumber: number;
    versionNumber: number;
    versionState: string;
}

export class DataverseBaseResult<P> {
    data: P;
    message: string;
    status: string;
}

export class DataverseFile {
    dataFile: DataverseFileData;
    datasetVersionId: number;
    description: string;
    directoryLabel: string;
    label: string;
    restrict: boolean;
    version: number;
}

export class DataverseFileAttachment {
    file: any;
    fileName: string;
}

export class DataverseFileData {
    checksum: DataverseFileDataChecksum;
    contentType: string;
    creationDate: string;
    description: string;
    filename: string;
    filesize: number;
    id: number;
    md5: string;
    persistentId: string;
    pidURL: string;
    storageIdentifier: string;
    termsOfAccess: string;
}

export class DataverseFileDataChecksum {
    type: string;
    value: string;
}

export class DataverseFileRestrictResult {
    message: string;
}

export class DataverseItem {
    authors: string[];
    citation: string;
    citationHtml: string;
    createdAt: Date;
    description: string;
    fileCount: number;
    global_id: string;
    identifier_of_dataverse: string;
    majorVersion: number;
    minorVersion: number;
    name: string;
    name_of_dataverse: string;
    producers: string[];
    published_at: Date;
    publisher: string;
    storageIdentifier: string;
    subjects: string[];
    type: string;
    updatedAt: Date;
    url: string;
    versionId: number;
    versionState: string;
}

export class DataverseNodeStatus {
    affiliation: string;
    alias: string;
    creationDate: Date;
    dataverseType: string;
    description: string;
    id: number;
    name: string;
    ownerId: number;
}

export class HomeStats {
    computedOn: Date;
    coordinates: Coordinate[];
    nbCompliant: number;
    nbContrib: number;
    nbSynchronizedContrib: number;
}

export class ImageAdministrativeData {
    compliant: boolean;
    contact: string;
    contactEmail?: string;
    contextComments?: string;
    distributor: string;
    funding?: string;
    previousPublicationComments?: string;
    previousPublicationDoi?: string;
    producer: string;
    project?: string;
    publications?: string;
}

export class ImageBootstrap {
    compliant: boolean;
    description: string;
    summary: string;
    timeSeries: boolean;
    timeSeriesId?: string;
}

export class ImageInfo {
    acquisitionDateTime: Date;
    acquisitionSite?: string;
    bitsPerPixel: number;
    calibration?: boolean;
    captureTechnology?: CaptureTechnology;
    captureTechnologyComplements?: string;
    captureTechnologySpecifications?: string;
    compliant: boolean;
    dimension: ImageDimension;
    imageSizeX: number;
    imageSizeY: number;
    imageSizeZ?: number;
    postAcquisitionTreatments: boolean;
    purpose?: string;
    resolutionUnit: ResolutionUnit;
    resolutionX: number;
    resolutionY: number;
    resolutionZ?: number;
    restrictions?: string;
    treatments?: string;
}

export class ImageMetadata {
    author: string;
    collaboraters: MetadataCollaborater[];
    compliant: boolean;
    currentIndex?: number;
    dataverseId: number;
    dataversePublicAvailabilityDate?: Date;
    dataverseUrl?: string;
    file?: ImageMetadataFile;
    filePublishable: boolean;
    id: string;
    imageAdministrativeData: ImageAdministrativeData;
    imageBootstrap: ImageBootstrap;
    imageInfo: ImageInfo;
    imageSampleInstructiveContext: ImageSampleInstructiveContext;
    imageSamplesInfo: ImageSamplesInfo;
    inputDate: Date;
    lastDataversePublication?: Date;
    lastDataverseSynchro?: Date;
    mayHavePreview: boolean;
    metadataPublishable: boolean;
    nextMetadataId?: string;
    permissions: ImageMetadataPermissions;
    persistentId?: string;
    persistentUrl?: string;
    previousMetadataId?: string;
    seriesLength?: number;
    updateDate: Date;
}

export class ImageMetadataFile {
    dataverseSynchro: boolean;
    fileFormat: string;
    fileId: string;
    fileName: string;
    fileSize: number;
    metadataId: string;
}

export interface ImageMetadataPermissions {
    canAddCollaborater: boolean;
    canAddIntoTimeSeries: boolean;
    canDelete: boolean;
    canDownloadFile: boolean;
    canDuplicate: boolean;
    canModify: boolean;
    canPublish: boolean;
    canTurnIntoTimeSeries: boolean;
    canViewCollaboraters: boolean;
}

export class ImageSampleInstructiveContext {
    agronomicComments?: string;
    culture?: string;
    fertilizationType?: string;
    horizonType?: HorizonType;
    landCover?: LandCoverType;
    lastFertilization?: Date;
    lastTillage?: Date;
    naturalCompliant: boolean;
    parentMaterial?: string;
    pedologicalComments?: string;
    practice?: Practice;
    practiceDepth?: number;
    samplingDepthBottom?: number;
    samplingDepthTop?: number;
    soilTillage?: string;
    soilTillageHorizon?: boolean;
    soilType?: SoilType;
    soilTypeOther?: string;
    soilTypeOtherClassificationSystem?: string;
    textureCarbonPercent?: number;
    textureClayPercent?: number;
    texturePH?: number;
    textureSandPercent?: number;
    textureSiltPercent?: number;
}

export class ImageSamplesInfo {
    compliant: boolean;
    externalComment?: string;
    externalEntryName?: string;
    externalSampleId?: string;
    externalSourceBase?: string;
    lte?: string;
    naturalCompliant: boolean;
    region: Region;
    sampleComment?: string;
    sampleSizeX: number;
    sampleSizeY: number;
    sampleSizeZ: number;
    sampleTypeAdditionalInformation?: string;
    samplingDateTime?: Date;
    samplingGuidance?: SamplingGuidance;
    samplingMethod?: string;
    samplingRowType?: SamplingRowType;
    shape: Shape;
    type: SampleType;
}

export interface InputPart {
    bodyAsString: string;
    contentTypeFromMessage: boolean;
    headers: any;
    mediaType: MediaType;
}

export class MediaType {
    parameters: { [index: string]: string };
    subtype: string;
    type: string;
    wildcardSubtype: boolean;
    wildcardType: boolean;
}

export class MetadataBlock {
    displayName: string;
    fields: MetadataField[];
}

export class MetadataBlocks {
    biomedical: MetadataBlock;
    citation: MetadataBlock;
    geospatial: MetadataBlock;
    journal: MetadataBlock;
    socialscience: MetadataBlock;
}

export class MetadataCollaborater {
    firstName: string;
    lastName: string;
    since: Date;
    userId: string;
}

export class MetadataField {
    multiple: boolean;
    typeClass: string;
    typeName: string;
}

export interface MultipartFormDataInput extends MultipartInput {
    formDataMap: { [index: string]: InputPart[] };
}

export interface MultipartInput {
    parts: InputPart[];
    preamble: string;
}

export class NodeContent {
    authority: string;
    description: string;
    id: number;
    identifier: string;
    latestVersion: DatasetVersion;
    persistentUrl: string;
    protocol: string;
    publicationDate: string;
    publisher: string;
    storageIdentifier: string;
    type: string;
}

export class PaginationOrder implements Serializable {
    clause: string;
    desc: boolean;
}

export class PaginationParameter implements Serializable {
    all: boolean;
    endIndex: number;
    orderClauses: PaginationOrder[];
    pageNumber: number;
    pageSize: number;
    startIndex: number;
}

export class PaginationResult<O> implements Serializable {
    count: number;
    currentPage: PaginationParameter;
    elements: O[];
    firstPage: PaginationParameter;
    lastPage: PaginationParameter;
    nextPage: PaginationParameter;
    pageCount: number;
    previousPage: PaginationParameter;
}

export class Region {
    country_en: string;
    country_fr: string;
    id: string;
    lat: number;
    lon: number;
    region_en: string;
    region_fr: string;
}

export class SearchForm {
    acquisitionDateTimeMax: Date;
    acquisitionDateTimeMin: Date;
    calibration?: boolean;
    captureTechnology?: CaptureTechnology;
    dimension: ImageDimension;
    fertilizationType?: string;
    horizonType?: HorizonType;
    landCover?: LandCoverType;
    lastFertilizationMax?: Date;
    lastFertilizationMin?: Date;
    lastTillageMax?: Date;
    lastTillageMin?: Date;
    pageNumber: number;
    pageSize: number;
    postAcquisitionTreatments: boolean;
    practice?: Practice;
    practiceDepthMax?: number;
    practiceDepthMin?: number;
    region?: Region;
    samplingDateTimeMax?: Date;
    samplingDateTimeMin?: Date;
    samplingDepth?: number;
    samplingGuidance?: SamplingGuidance;
    samplingMethod?: string;
    samplingRowType?: SamplingRowType;
    shape: Shape;
    soilTillageHorizon?: boolean;
    soilType?: SoilType;
    summary: string;
    textureCarbonPercentMax?: number;
    textureCarbonPercentMin?: number;
    textureClayPercentMax?: number;
    textureClayPercentMin?: number;
    texturePHMax?: number;
    texturePHMin?: number;
    textureSandPercentMax?: number;
    textureSandPercentMin?: number;
    textureSiltPercentMax?: number;
    textureSiltPercentMin?: number;
    timeSeriesId?: string;
    type: SampleType;
}

export class SearchResult<T> {
    count_in_response: number;
    items: T[];
    q: string;
    start: number;
    total_count: number;
}

export interface Serializable {
}

export class UploadDataset {
    datasetVersion: DatasetVersion;
}

export type CaptureTechnology = "MEDICAL_SCANNER" | "INDUSTRIAL_SCANNER" | "TWO_D_SCANNER" | "MICROSCOPE" | "OTHER";

export type HorizonType = "H" | "O" | "A" | "E" | "B" | "C" | "R";

export type ImageDimension = "TWO_D" | "THREE_D";

export type LandCoverType = "URBAN_FABRIC" | "INDUSTRIAL_COMMERCIAL_AND_TRANSPORT_UNITS" | "MINE_DUMP_AND_CONSTRUCTION_SITES" | "ARTIFICIAL_NON_AGRICULTURAL_VEGETATED_AREAS" | "ARABLE_LAND" | "PERMANENT_CROPS" | "PASTURES" | "HETEROGENEOUS_AGRICULTURAL_AREAS" | "FORESTS" | "SCRUB_AND_OR_HERBACEOUS_VEGETATION_ASSOCIATIONS" | "OPEN_SPACE_WITH_LITTLE_OR_NO_VEGETATION" | "INLAND_WETLANDS" | "MARITIME_WETLANDS" | "INLAND_WATERS" | "MARINE_WATERS";

export type Practice = "PLOUGHING" | "SUPERFICIAL" | "NONE" | "OTHER";

export type ResolutionUnit = "cm" | "mm" | "um";

export type SampleType = "NATURAL" | "REPACKED" | "RECONSTITUTED";

export type SamplingGuidance = "HORIZONTAL" | "VERTICAL" | "OTHER";

export type SamplingRowType = "ROW" | "INTER_ROW";

export type Shape = "CYLINDER" | "CUBE" | "AGGREGATE" | "OTHER";

export type SoilType = "Acrisols" | "Alisols" | "Andosols" | "Anthrosols" | "Arenosols" | "Calcisols" | "Cambisols" | "Chernozems" | "Cryosols" | "Durisols" | "Ferralsols" | "Fluvisols" | "Gleysols" | "Gypsisols" | "Histosols" | "Kastanozems" | "Leptosols" | "Lixisols" | "Luvisols" | "Nitisols" | "Phaeozems" | "Planosols" | "Plinthosols" | "Podzols" | "Solonchaks" | "Solonetz" | "Stagnosols" | "Technosols" | "Umbrisols" | "Vertisols";

export type TimeSeriesUpdateMethod = "CONTRIBUTION_ONLY" | "TIME_SERIES";
