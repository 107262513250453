import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contribute" }
const _hoisted_2 = { class: "columns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_input = _resolveComponent("o-input")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_FormSection = _resolveComponent("FormSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormSection, {
        class: "column",
        title: "contribute.step2.timeSeries"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_FormGroup, { label: "contribute.step2.timeSeries" }, {
            default: _withCtx(() => [
              _createVNode(_component_o_field, {
                label: _ctx.$t('contribute.step2.timeSeriesId')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_o_input, {
                    modelValue: _ctx.filters.timeSeriesId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.timeSeriesId) = $event)),
                    placeholder: "ABC-123"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_FormSection, {
        class: "column",
        title: "contribute.step1.imageInfo"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_FormGroup, { label: "contribute.step1.imageInfo" }, {
            default: _withCtx(() => [
              _createVNode(_component_o_field, {
                label: _ctx.$t('contribute.step1.summary')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_o_input, {
                    modelValue: _ctx.filters.summary,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.summary) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}