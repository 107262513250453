import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_o_tooltip = _resolveComponent("o-tooltip")!

  return (_openBlock(), _createBlock(_component_o_tooltip, {
    class: "tooltip",
    label: _ctx.label,
    multiline: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Icon, { name: "help" })
    ]),
    _: 1
  }, 8, ["label"]))
}