
import { Options, Vue } from 'vue-class-component'

import Tag from '@/components/Tag.vue'
import Icon from '@/components/Icon.vue'
import FormSection from '@/components/FormSection.vue'
import FormGroup from '@/components/FormGroup.vue'
import ContributeSteps from '@/components/ContributeSteps.vue'
import { SearchForm } from '@/model/BackendDTOs'

@Options({
  props: {
    filters: SearchForm
  },
  components: {
    ContributeSteps,
    FormSection,
    FormGroup,
    Icon,
    Tag
  }
})
export default class SearchStep1Bootstrap extends Vue {
  filters: SearchForm
}
