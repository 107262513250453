import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "search-results" }
const _hoisted_2 = { class: "search-results-header" }
const _hoisted_3 = { class: "count" }
const _hoisted_4 = { class: "search-results-table" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "empty-page-placeholder"
}
const _hoisted_7 = {
  key: 2,
  class: "pagination"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_table_column = _resolveComponent("o-table-column")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_o_table = _resolveComponent("o-table")!
  const _component_o_pagination = _resolveComponent("o-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.results.count) + " " + _toDisplayString(_ctx.results.count > 1 ? _ctx.$t('search.results') : _ctx.$t('search.result')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.results.elements.length > 0)
        ? (_openBlock(), _createBlock(_component_o_table, {
            key: 0,
            data: _ctx.results.elements,
            bordered: true,
            striped: true,
            hoverable: true,
            onClick: _ctx.rowSelected
          }, {
            default: _withCtx(() => [
              _createVNode(_component_o_table_column, {
                field: "doi",
                label: _ctx.$t('contribute.step6.persistentId')
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString(props.row.persistentId), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                field: "summary",
                label: _ctx.$t('contribute.step1.summary')
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString(props.row.imageBootstrap.summary), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                field: "author",
                label: _ctx.$t('contribute.contributor')
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString(props.row.author), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                field: "acquisitionDateTime",
                label: _ctx.$t('contribute.step2.acquisitionDateTime')
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString(_ctx.formatLocalDateTime(props.row.imageInfo?.acquisitionDateTime)), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                field: "timeSeries",
                label: _ctx.$t('contribute.step2.timeSeries')
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString(_ctx.$t('forms.boolean_' + props.row.imageInfo?.timeSeries)) + " ", 1),
                  (props.row.imageInfo?.timeSeries)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, "(" + _toDisplayString(props.row.imageInfo?.timeSeriesId) + ")", 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                field: "publication",
                label: _ctx.$t('search.publication'),
                position: "centered"
              }, {
                default: _withCtx((props) => [
                  (props.row.lastDataversePublication)
                    ? (_openBlock(), _createBlock(_component_Icon, {
                        key: 0,
                        name: "list",
                        title: _ctx.$t('search.metadataAvailableOnDataverse')
                      }, null, 8, ["title"]))
                    : _createCommentVNode("", true),
                  (props.row.dataversePublicAvailabilityDate)
                    ? (_openBlock(), _createBlock(_component_Icon, {
                        key: 1,
                        name: "insert_drive_file",
                        title: _ctx.$t('search.fileAvailableOnDataverse')
                      }, null, 8, ["title"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["data", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.results.elements.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('search.noResult')), 1))
        : _createCommentVNode("", true),
      (_ctx.results.currentPage.pageSize < _ctx.results.count)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_o_pagination, {
              total: _ctx.results.count,
              current: _ctx.results.currentPage.pageNumber + 1,
              "range-before": 3,
              "range-after": 3,
              order: "centered",
              simple: false,
              rounded: false,
              "per-page": _ctx.results.currentPage.pageSize,
              onChange: _ctx.selectPage
            }, null, 8, ["total", "current", "per-page", "onChange"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}