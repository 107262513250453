export interface Config {
    getAuthority:() => string;
    getClientId:() => string;
    getBackend:() => string;
}

// OpenID Connect authority URL
const AUTHORITY = (<any>window).AUTHORITY || process.env.VUE_APP_AUTHORITY || 'http://localhost:58080/auth/realms/imagesol'

// OpenID Connect client ID
const CLIENT_ID = (<any>window).CLIENT_ID || process.env.VUE_APP_CLIENT_ID || 'imagesol-frontend'

// Backend base URL
const BACKEND = (<any>window).BACKEND || process.env.VUE_APP_BACKEND || 'http://localhost:8080/api'

export class ConfigImpl implements Config {

    getAuthority(): string {
        const result = AUTHORITY;
        return result;
    }

    getClientId(): string {
        const result = CLIENT_ID;
        return result;
    }

    getBackend(): string {
        let result = BACKEND;
        // Si pas de HTTP(S) on ajoute l'origine courante au début
        if (result.indexOf('http') === -1) {
            // Si pas de / au début, on l'ajoute
            if (result.indexOf('/') !== 0) {
                result = '/' + result;
            }
            result = window.location.origin + result;
        }
        return result;
    }

}
const instance:Config = new ConfigImpl();

export default instance
