
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    name: String,
    rotate: Boolean
  }
})
export default class Icon extends Vue {
}
