
import { prop, Options, Vue } from 'vue-class-component'
import Icon from '@/components/Icon.vue'
import { SearchForm } from '@/model/BackendDTOs';
import SearchStep1Bootstrap from './search/SearchStep1Bootstrap.vue';
import SearchStep2Technical from './search/SearchStep2Technical.vue';
import SearchStep3Sample from './search/SearchStep3Sample.vue';
import SearchStep4Instructive from './search/SearchStep4Instructive.vue';

// Use props object to be able to use it in methods
export class Props {
  filters = prop<SearchForm>({ required: true });
}

@Options({
  components: {
    Icon,
    SearchStep1Bootstrap,
    SearchStep2Technical,
    SearchStep3Sample,
    SearchStep4Instructive
  }
})
export default class SearchFilters extends Vue.with(Props) {

  selectedStep = 1

  isEmpty(property: any) {
    return property !== undefined && property !== "" && property !== null
  }

  hasStep1Filters() {
    return this.isEmpty(this.filters.summary) ||
           this.isEmpty(this.filters.timeSeriesId)
  }

  hasStep2Filters() {
    return this.isEmpty(this.filters.dimension) ||
           this.isEmpty(this.filters.captureTechnology) ||
           this.isEmpty(this.filters.acquisitionDateTimeMin) ||
           this.isEmpty(this.filters.acquisitionDateTimeMax) ||
           this.isEmpty(this.filters.calibration) ||
           this.isEmpty(this.filters.postAcquisitionTreatments)
  }

  hasStep3Filters() {
    return this.isEmpty(this.filters.type) ||
          this.isEmpty(this.filters.shape) ||
          this.isEmpty(this.filters.samplingDateTimeMin) ||
          this.isEmpty(this.filters.samplingDateTimeMax) ||
          this.isEmpty(this.filters.region) ||
          this.isEmpty(this.filters.samplingMethod) ||
          this.isEmpty(this.filters.samplingGuidance) ||
          this.isEmpty(this.filters.samplingRowType)
  }

  hasStep4Filters() {
    return this.isEmpty(this.filters.soilType) ||
          this.isEmpty(this.filters.samplingDepth) ||
          this.isEmpty(this.filters.horizonType) ||
          this.isEmpty(this.filters.soilTillageHorizon) ||
          this.isEmpty(this.filters.textureClayPercentMin) ||
          this.isEmpty(this.filters.textureClayPercentMax) ||
          this.isEmpty(this.filters.textureSiltPercentMin) ||
          this.isEmpty(this.filters.textureSiltPercentMax) ||
          this.isEmpty(this.filters.textureSandPercentMin) ||
          this.isEmpty(this.filters.textureSandPercentMax) ||
          this.isEmpty(this.filters.textureCarbonPercentMin) ||
          this.isEmpty(this.filters.textureCarbonPercentMax) ||
          this.isEmpty(this.filters.texturePHMin) ||
          this.isEmpty(this.filters.texturePHMax) ||
          this.isEmpty(this.filters.landCover) ||
          this.isEmpty(this.filters.lastTillageMin) ||
          this.isEmpty(this.filters.lastTillageMax) ||
          this.isEmpty(this.filters.fertilizationType) ||
          this.isEmpty(this.filters.lastFertilizationMin) ||
          this.isEmpty(this.filters.lastFertilizationMax) ||
          this.isEmpty(this.filters.practice) ||
          this.isEmpty(this.filters.practiceDepthMin) ||
          this.isEmpty(this.filters.practiceDepthMax)
  }
}
