import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "columns contribute-skeleton" }
const _hoisted_2 = { class: "column is-half" }
const _hoisted_3 = { class: "column is-half" }
const _hoisted_4 = { class: "contribute-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_skeleton = _resolveComponent("o-skeleton")!
  const _component_o_button = _resolveComponent("o-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_o_skeleton, { width: "20%" }),
        _createVNode(_component_o_skeleton, {
          size: "large",
          width: "60%"
        }),
        _createVNode(_component_o_skeleton, { width: "15%" }),
        _createVNode(_component_o_skeleton, {
          size: "large",
          width: "80%"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_o_skeleton, { width: "20%" }),
        _createVNode(_component_o_skeleton, {
          size: "large",
          width: "70%"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_o_button, {
        disabled: "",
        inverted: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('forms.cancel')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_o_button, {
        disabled: "",
        outlined: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('forms.previous')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_o_button, { disabled: "" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('forms.next')), 1)
        ]),
        _: 1
      })
    ])
  ], 64))
}