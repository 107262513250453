

import { Vue } from 'vue-class-component'
import anonymousFetchGet from '@/anonymousFetchGet'
import { HomeStats } from "@/model/BackendDTOs"
import { error } from '@/Toasts'

export default class Home extends Vue {

  contribs = 0;

  mounted() {
    const script = document.createElement("script");
    script.innerHTML = "var mymap = L.map('mapid').setView([46.5, 1.9], 5);";
    script.innerHTML += "L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(mymap);"
    anonymousFetchGet(`/v1/imagesol/stats`)
      .then(
        (res) => {
          res.json().then((data:HomeStats) => {
            this.contribs = data.nbContrib;
            if (data.coordinates) {
              data.coordinates.forEach((c) => {
                script.innerHTML += `L.marker([${c.coordinateWgs84X}, ${c.coordinateWgs84Y}]).addTo(mymap);`
              })
            }
            document.head.appendChild(script);
          })
        },
        (e) => {
          error(this.$oruga, `Impossible de charger les statistiques: ${e}`)
          document.head.appendChild(script);
        })
  }
}
