import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "upload-row" }
const _hoisted_2 = { class: "contribution-name" }
const _hoisted_3 = {
  key: 0,
  class: "upload-percent"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.contributionTitle), 1),
    (!_ctx.complete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(Math.floor(_ctx.percent)) + "% ", 1))
      : _createCommentVNode("", true),
    (_ctx.complete)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 1,
          class: "upload-complete",
          name: "done"
        }))
      : _createCommentVNode("", true)
  ]))
}