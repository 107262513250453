// Vue
import { createApp } from 'vue'

// Plugins
import i18n from '@/i18n'
import router from '@/router'
import oruga from '@oruga-ui/oruga-next'

// Main view and layout(s)
import App from '@/App.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue';

// startup
const app = createApp(App);
app
  .use(i18n)
  .use(router)
  .use(oruga, {
    button: {
      rounded: true
    }
  })
  .component('default-layout', DefaultLayout)
  .mount('#app')

// Pour que this.$oruga soit accessible depuis les composants
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $oruga: typeof oruga
  }
}
