
import { Options, Vue } from 'vue-class-component'

import UploadRow from '@/components/UploadRow.vue'

import StartUploadEvent from '@/model/StartUploadEvent'
import UploadInProgress from '@/model/UploadInProgress'

import events from '@/events'

import xhrPostFile from '@/xhrPostFile'

import { error } from '@/Toasts'

@Options({
  components: {
    UploadRow
  }
})
export default class UploadModal extends Vue {

    uploads:UploadInProgress[] = []

    created() {
        events.on('start-upload', this.startUpload);
    }

    startUpload(a:any) {
        const event:StartUploadEvent = a;
        console.log("Received new upload start event", event)
        const newUpload:UploadInProgress = {
            contributionId: event.contributionId,
            contributionTitle: event.contributionTitle,
            percent: 0,
            complete: false
        };
        this.uploads.push(newUpload)

        const watchProgress = (e:ProgressEvent<XMLHttpRequestEventTarget>) => {
            const percentCompleted = (e.loaded / e.total) * 100;
            newUpload.percent = percentCompleted;
            this.$forceUpdate();
        }

        const data = new FormData()
        let url = `/v1/imagesol/contribution/${event.contributionId}/file`;
        data.append('uploadedFile', event.file)
        if (event.file.name.endsWith(".zip")) {
            url = `/v1/imagesol/contribution/${event.contributionId}/zip`;
        }
        xhrPostFile(url, data, watchProgress)
            .then(
                (status) => {
                    if (status === 200 || status === 204) {
                        newUpload.complete = true;
                        this.$forceUpdate();
                        setTimeout(() => this.clearUpload(event.contributionId), 20000)
                    } else {
                        error(this.$oruga, "Une erreur est survenue pendant le téléversement du fichier")
                    }
                }, () => {
                    error(this.$oruga, "Une erreur est survenue pendant le téléversement du fichier")
                });
    }

    clearUpload(id:string) {
        let foundAtIndex = -1;
        for (let i = 0; i < this.uploads.length; i++) {
            const someUpload = this.uploads[i];
            if (id === someUpload.contributionId) {
                foundAtIndex = i;
            }
        }
        if (foundAtIndex >= 0) {
            this.uploads.splice(foundAtIndex, 1);
        }
    }
}
