import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_step_item = _resolveComponent("o-step-item")!
  const _component_o_steps = _resolveComponent("o-steps")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_o_steps, {
      modelValue: _ctx.foutaises.activeStep,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.foutaises.activeStep) = $event)),
      animated: false,
      rounded: true,
      "has-navigation": false,
      "label-position": 'bottom',
      rootClass: "contribute-steps"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_o_step_item, {
          step: "1",
          label: _ctx.$t('contribute.step1.title'),
          clickable: _ctx.step > 1 || _ctx.compliant
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contribute.step1.title')), 1)
          ]),
          _: 1
        }, 8, ["label", "clickable"]),
        _createVNode(_component_o_step_item, {
          step: "2",
          label: _ctx.$t('contribute.step2.title'),
          clickable: _ctx.step > 2 || _ctx.compliant
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contribute.step2.title')), 1)
          ]),
          _: 1
        }, 8, ["label", "clickable"]),
        _createVNode(_component_o_step_item, {
          step: "3",
          label: _ctx.$t('contribute.step3.title'),
          clickable: _ctx.step > 3 || _ctx.compliant
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contribute.step3.title')), 1)
          ]),
          _: 1
        }, 8, ["label", "clickable"]),
        _createVNode(_component_o_step_item, {
          step: "4",
          label: _ctx.$t('contribute.step4.title'),
          clickable: _ctx.step > 4 || _ctx.compliant
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contribute.step4.title')), 1)
          ]),
          _: 1
        }, 8, ["label", "clickable"]),
        _createVNode(_component_o_step_item, {
          step: "5",
          label: _ctx.$t('contribute.step5.title'),
          clickable: _ctx.compliant
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contribute.step5.title')), 1)
          ]),
          _: 1
        }, 8, ["label", "clickable"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}