
import { Options, Vue } from 'vue-class-component'

import Icon from '@/components/Icon.vue'
import Tag from '@/components/Tag.vue'
import Tooltip from '@/components/Tooltip.vue'
import FormSection from '@/components/FormSection.vue'
import FormGroup from '@/components/FormGroup.vue'

import { ImageDimension, ResolutionUnit, CaptureTechnology, SearchForm } from '@/model/BackendDTOs'
import ThreeStateToggle from '../ThreeStateToggle.vue'

@Options({
  props: {
    filters: SearchForm
  },
  components: {
    FormSection,
    FormGroup,
    Icon,
    Tag,
    Tooltip,
    ThreeStateToggle
  }
})
export default class SearchStep2Technical extends Vue {

  filters: SearchForm

  dimensions:ImageDimension[] = ['TWO_D', 'THREE_D']
  resolutionUnits:ResolutionUnit[] = ['cm', 'mm', 'um']
  captureTechnologies:CaptureTechnology[] = ["MEDICAL_SCANNER", "INDUSTRIAL_SCANNER", "TWO_D_SCANNER", "MICROSCOPE", "OTHER"]
}
