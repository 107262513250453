
import { Options, Vue } from 'vue-class-component'

import Icon from '@/components/Icon.vue'
@Options({
  props: {
    contributionTitle: String,
    percent: Number,
    complete: Boolean
  },
  components: {
    Icon
  }
})
export default class UploadRow extends Vue {
}
