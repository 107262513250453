
import fetchGet from '@/fetchGet'
import fetchDelete from '@/fetchDelete'
import fetchPost from '@/fetchPost'
import fetchPut from '@/fetchPut'

import config from '@/Config'

import { ImageMetadata, ImageBootstrap, ImageInfo, ImageSamplesInfo, ImageSampleInstructiveContext, ImageAdministrativeData, MetadataCollaborater, TimeSeriesUpdateMethod } from '@/model/BackendDTOs'
import { string } from 'yup/lib/locale'

const BACKEND = config.getBackend();

const safeLocalDate = (input:Date|undefined):string|undefined => {
    if (!input) {
        return input;
    }
    const monthNumber = input.getMonth() + 1;
    const dayNumber = input.getDate();
    const formattedDate = input.getFullYear() + '-' + (monthNumber < 10 ? '0' : '') + monthNumber + '-' + (dayNumber < 10 ? '0' : '') + dayNumber;
    return formattedDate;
}

const safeLocalDateTime = (input:Date|undefined):string|undefined => {
    if (!input) {
        return input;
    }
    const localDate = safeLocalDate(input);
    const formattedDate = localDate + 'T' + input.toLocaleTimeString();
    return formattedDate;
}

function handleError(res:Response, reject:(reason?: any) => void) {
    if (res.headers.get('Content-Type') === 'application/json') {
        res.json().then(reject, reject);
    } else {
        reject(new Error(res.statusText))
    }
}

export class ContributionService {
    loadMetadata(id:string):Promise<ImageMetadata> {
        return new Promise<ImageMetadata>((resolve, reject) => {

            fetchGet(`/v1/imagesol/contribution/${id}`)
                .then((res) => {
                    if (res.ok) {
                        res.json().then((data:ImageMetadata) => {
                            if (data.imageInfo && data.imageInfo.acquisitionDateTime) {
                                data.imageInfo.acquisitionDateTime = new Date(data.imageInfo.acquisitionDateTime);
                            }
                            if (data.imageSampleInstructiveContext && data.imageSampleInstructiveContext.lastFertilization) {
                                data.imageSampleInstructiveContext.lastFertilization = new Date(data.imageSampleInstructiveContext.lastFertilization);
                            }
                            if (data.imageSampleInstructiveContext && data.imageSampleInstructiveContext.lastTillage) {
                                data.imageSampleInstructiveContext.lastTillage = new Date(data.imageSampleInstructiveContext.lastTillage);
                            }
                            if (data.imageSamplesInfo && data.imageSamplesInfo.samplingDateTime) {
                                data.imageSamplesInfo.samplingDateTime = new Date(data.imageSamplesInfo.samplingDateTime);
                            }
                            if (data.updateDate) {
                                data.updateDate = new Date(data.updateDate);
                            }
                            if (data.lastDataverseSynchro) {
                                data.lastDataverseSynchro = new Date(data.lastDataverseSynchro);
                            }
                            if (data.lastDataversePublication) {
                                data.lastDataversePublication = new Date(data.lastDataversePublication);
                            }
                            if (data.dataversePublicAvailabilityDate) {
                                data.dataversePublicAvailabilityDate = new Date(data.dataversePublicAvailabilityDate);
                            }
                            resolve(data);
                        })
                    } else {
                        reject(new Error(res.statusText))
                    }
                },
                reject);

        });
    }

    // loadCollaboraters(id:string):Promise<ImageMetadata> {
    //     return new Promise<ImageMetadata>((resolve, reject) => {
    //         fetchGet(`/v1/imagesol/contribution/${id}/collaboraters`)
    //             .then((res) => {
    //                 if (res.ok) {
    //                     res.json().then((data:any) => {
    //                         resolve(data);
    //                     })
    //                 } else {
    //                     reject(new Error(res.statusText))
    //                 }
    //             },
    //             reject);
    //     });
    // }

    createImageBootstrap(model:ImageBootstrap):Promise<string> {
        return new Promise<string>((resolve, reject) => {

            fetchPost(`/v1/imagesol/contribution`, model)
                .then(
                    (res) => {
                        if (res.ok) {
                            res.json().then(
                                (id:string) => {
                                    resolve(id)
                                },
                                reject)
                        } else {
                            reject(new Error(res.statusText))
                        }
                    },
                    reject)

        });
    }

    saveImageBootstrap(id:string, model:ImageBootstrap, tsum?:TimeSeriesUpdateMethod):Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let queryParams = {};
            if (tsum) {
                queryParams = { tsum };
            }
            fetchPut(`/v1/imagesol/contribution/${id}/bootstrap`, model, queryParams)
                .then(
                    (res) => {
                        if (res.ok) {
                            resolve()
                        } else {
                            handleError(res, reject)
                        }
                    },
                    reject)

        });
    }

    // TODO

    saveImageInfo(id:string, model:ImageInfo, tsum?:TimeSeriesUpdateMethod):Promise<void> {
        const dateSafeBody:any = { ...model };
        dateSafeBody.acquisitionDateTime = safeLocalDateTime(dateSafeBody.acquisitionDateTime)
        return new Promise<void>((resolve, reject) => {
            let queryParams = {};
            if (tsum) {
                queryParams = { tsum };
            }
            fetchPut(`/v1/imagesol/contribution/${id}/acquisition`, dateSafeBody, queryParams)
                .then(
                    (res) => {
                        if (res.ok) {
                            resolve()
                        } else {
                            handleError(res, reject)
                        }
                    },
                    reject)

        });
    }

    saveImageSamplesInfo(id:string, model:ImageSamplesInfo, tsum?:TimeSeriesUpdateMethod):Promise<void> {
        const dateSafeBody:any = { ...model };
        dateSafeBody.samplingDateTime = safeLocalDateTime(dateSafeBody.samplingDateTime)
        return new Promise<void>((resolve, reject) => {
            let queryParams = {};
            if (tsum) {
                queryParams = { tsum };
            }
            fetchPut(`/v1/imagesol/contribution/${id}/sample`, dateSafeBody, queryParams)
                .then(
                    (res) => {
                        if (res.ok) {
                            resolve()
                        } else {
                            handleError(res, reject)
                        }
                    },
                    reject)

        });
    }

    saveImageSampleInstructiveContext(id:string, model:ImageSampleInstructiveContext, tsum?:TimeSeriesUpdateMethod):Promise<void> {
        const dateSafeBody:any = { ...model };
        dateSafeBody.lastFertilization = safeLocalDate(dateSafeBody.lastFertilization)
        dateSafeBody.lastTillage = safeLocalDate(dateSafeBody.lastTillage)
        return new Promise<void>((resolve, reject) => {
            let queryParams = {};
            if (tsum) {
                queryParams = { tsum };
            }
            fetchPut(`/v1/imagesol/contribution/${id}/instructive`, dateSafeBody, queryParams)
                .then(
                    (res) => {
                        if (res.ok) {
                            resolve()
                        } else {
                            handleError(res, reject)
                        }
                    },
                    reject)

        });
    }

    saveImageAdministrativeData(id:string, model:ImageAdministrativeData, tsum?:TimeSeriesUpdateMethod):Promise<void> {
        const dateSafeBody:any = { ...model };
        return new Promise<void>((resolve, reject) => {
            let queryParams = {};
            if (tsum) {
                queryParams = { tsum };
            }
            fetchPut(`/v1/imagesol/contribution/${id}/administrative`, dateSafeBody, queryParams)
                .then(
                    (res) => {
                        if (res.ok) {
                            resolve()
                        } else {
                            handleError(res, reject)
                        }
                    },
                    reject)

        });
    }

    deleteMetadata(id:string):Promise<void> {
        return new Promise<void>((resolve, reject) => {
            fetchDelete(`/v1/imagesol/contribution/${id}`)
                .then(
                    (res) => {
                        if (res.ok) {
                            resolve()
                        } else {
                            reject(new Error(res.statusText))
                        }
                    },
                    reject)

        });
    }

    computeDownloadUrl(metadata:ImageMetadata, token?:string):string {
        let uri = `/v1/imagesol/contribution/${metadata.id}/file/${metadata.file?.fileId}/download`
        if (token) {
           uri += `?token=${token}`;
        }
        const url = new URL(`${BACKEND}${uri}`);
        return url.toString();
    }

    getDownloadUrl(metadata:ImageMetadata):Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const uri = `/v1/imagesol/contribution/${metadata.id}/file/${metadata.file?.fileId}/download`
            fetchPost(uri)
                .then(
                    (res) => {
                        if (res.ok) {
                            res.text()
                                .then(
                                    (token:string) => {
                                        const downloadUrl = this.computeDownloadUrl(metadata, token);
                                        resolve(downloadUrl);
                                    },
                                    reject
                                );
                        } else {
                            reject(new Error(res.statusText))
                        }
                    },
                    reject)
        });
    }

    getPreviewUrl(metadata:ImageMetadata):string {
        const uri = `/v1/imagesol/contribution/${metadata.id}/file/${metadata.file?.fileId}/preview`
        const url = new URL(`${BACKEND}${uri}`);
        return url.toString();
    }

    searchCollaborater(term:string, metadataId:string):Promise<MetadataCollaborater[]> {
        return new Promise<MetadataCollaborater[]>((resolve, reject) => {
            const query = {
                term
            };
            fetchGet(`/v1/imagesol/contribution/${metadataId}/collaboraters/search`, query)
                .then((res) => {
                    if (res.ok) {
                        res.json().then((data:MetadataCollaborater[]) => {
                            resolve(data);
                        })
                    } else {
                        reject(new Error(res.statusText))
                    }
                },
                reject);

        });
    }

    addCollaborater(userId:string, metadataId:string):Promise<void> {
        return new Promise<void>((resolve, reject) => {
            fetchPut(`/v1/imagesol/contribution/${metadataId}/collaboraters/${userId}`)
                .then((res) => {
                    if (res.ok) {
                        resolve();
                    } else {
                        reject(new Error(res.statusText))
                    }
                },
                reject);
        });
    }

    deleteCollaborater(userId:string, metadataId:string):Promise<void> {
        return new Promise<void>((resolve, reject) => {
            fetchDelete(`/v1/imagesol/contribution/${metadataId}/collaboraters/${userId}`)
                .then((res) => {
                    if (res.ok) {
                        resolve();
                    } else {
                        reject(new Error(res.statusText))
                    }
                },
                reject);
        });
    }

    duplicateContribution(metadataId:string):Promise<string> {
        return new Promise<string>((resolve, reject) => {
            fetchPost(`/v1/imagesol/contribution/${metadataId}`)
                .then((res) => {
                    if (res.ok) {
                        res.json().then(resolve, reject);
                    } else {
                        reject(new Error(res.statusText))
                    }
                },
                reject);
        });
    }

    turnIntoTimeSeries(metadataId:string):Promise<string> {
        return new Promise<string>((resolve, reject) => {
            fetchPost(`/v1/imagesol/contribution/${metadataId}/time-series`)
                .then((res) => {
                    if (res.ok) {
                        res.text().then(resolve, reject);
                    } else {
                        reject(new Error(res.statusText))
                    }
                },
                reject);
        });
    }

    publishMetadata(metadataId:string):Promise<void> {
        return new Promise<void>((resolve, reject) => {
            fetchPut(`/v1/imagesol/contribution/${metadataId}/publish`)
                .then((res) => {
                    if (res.ok) {
                        resolve();
                    } else {
                        reject(new Error(res.statusText))
                    }
                },
                reject);
        });
    }

    publishFile(metadata:ImageMetadata):Promise<void> {
        return new Promise<void>((resolve, reject) => {
            fetchPut(`/v1/imagesol/contribution/${metadata.id}/file/${metadata.file?.fileId}/publish`)
                .then((res) => {
                    if (res.ok) {
                        resolve();
                    } else {
                        reject(new Error(res.statusText))
                    }
                },
                reject);
        });
    }

}

const instance = new ContributionService()

export default instance
