import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "metadata-block" }
const _hoisted_2 = { class: "metadata-block-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "metadata-block-bullet",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clicked', _ctx.id)))
    }, [
      _createVNode(_component_Icon, {
        name: (_ctx.selected !== _ctx.id)?'chevron_right':'expand_more'
      }, null, 8, ["name"])
    ]),
    _createElementVNode("div", {
      class: "metadata-block-title",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('clicked', _ctx.id)))
    }, _toDisplayString(_ctx.label), 1),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", null, [
        _createElementVNode("tbody", null, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ], 512), [
      [_vShow, _ctx.selected === _ctx.id]
    ])
  ]))
}