
import { Options, Vue } from 'vue-class-component'

import authService from '@/auth'
import { info } from '@/Toasts'

import Icon from '@/components/Icon.vue'
import UploadModal from '@/components/UploadModal.vue'
import { ImagesolUser } from '@/auth/ImagesolUser'

@Options({
  components: {
    Icon,
    UploadModal
  }
})
export default class DefaultLayout extends Vue {

  authenticated = false

  created() {
    const previousLocale = localStorage.getItem('imagesol-locale');
    console.debug('Previous locale', previousLocale)
    this.$i18n.locale = previousLocale || 'fr'
  }

  mounted () {
    authService.getUser().then(this.getUserCallback, console.error)
  }

  getUserCallback(user:ImagesolUser|null) {
    if (user) {
      if (user.expired) {
        console.log("Looks like token has expired, do logout to go back to a normal state")
        authService.logout()
      } else {
        this.authenticated = true
        const welcomeMessage = `Bonjour ${user.profile.given_name}`;
        info(this.$oruga, welcomeMessage);
      }
    } else {
      this.authenticated = false
    }
  }

  isHome() {
    return (this.$route.meta.home || false);
  }

  hasFooter() {
    return (this.$route.meta.footer || this.isHome());
  }

  getSection() {
    return (this.$route.meta.section || 'home');
  }

  switchLocale () {
    if (this.$i18n.locale === 'en') {
      this.$i18n.locale = 'fr';
    } else {
      this.$i18n.locale = 'en';
    }
    localStorage.setItem('imagesol-locale', this.$i18n.locale);
  }

  login () {
    const currentPath = this.$route.path;
    authService.login(currentPath);
  }

  account () {
    window.location.href = authService.getAccountUrl()
  }

  logout () {
    authService.logout()
  }
}

