
import { PaginationResult } from '@/model/BackendDTOs';
import { Options, Vue } from 'vue-class-component'

import Icon from '@/components/Icon.vue'

@Options({
  props: {
      results: PaginationResult
  },
  components: {
    Icon
  }
})
export default class SearchResults extends Vue {
    formatLocalDateTime(input?:any):string {
        if (!input) {
            return ""
        }
        const date = new Date(input);
        const locale = this.$i18n.locale;
        const dateString = date.toLocaleDateString(locale, { weekday: "long", year: 'numeric', month: 'long', day: 'numeric' });
        const hourString = date.toLocaleTimeString(locale, { hour: "2-digit", minute: "2-digit" });
        return `${dateString} à ${hourString}`
    }

    rowSelected(row:any) {
        if (row.compliant) {
            this.$router.push(`/read/${row.id}`)
        } else {
            this.$router.push(`/contribute/${row.id}`)
        }
    }

    selectPage(pageNumber:number) {
        this.$emit("page-selected", pageNumber - 1)
    }
}
