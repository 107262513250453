import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "contribute" }
const _hoisted_2 = { class: "page-header big" }
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = {
  key: 1,
  class: "columns",
  id: "formTop"
}
const _hoisted_5 = {
  key: 2,
  class: "contribute-buttons"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ContributeSteps = _resolveComponent("ContributeSteps")!
  const _component_ContributeSkeleton = _resolveComponent("ContributeSkeleton")!
  const _component_o_input = _resolveComponent("o-input")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_FormSection = _resolveComponent("FormSection")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TimeSeriesUpdateConflict = _resolveComponent("TimeSeriesUpdateConflict")!
  const _component_o_modal = _resolveComponent("o-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.metadata)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.metadata.compliant ? _ctx.$t("contribute.updateTitle") : _ctx.$t("contribute.title")), 1))
        : _createCommentVNode("", true),
      (_ctx.metadata)
        ? (_openBlock(), _createBlock(_component_Tag, {
            key: 1,
            text: _ctx.metadata.imageBootstrap.summary
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ContributeSteps, {
      step: 5,
      id: _ctx.id,
      compliant: _ctx.metadata?.compliant
    }, null, 8, ["id", "compliant"]),
    (!_ctx.model)
      ? (_openBlock(), _createBlock(_component_ContributeSkeleton, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.model)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_FormSection, {
            class: "column",
            title: "contribute.step5.rightsSection"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormGroup, { label: "contribute.step5.identification" }, {
                default: _withCtx(() => [
                  _createVNode(_component_o_field, {
                    label: _ctx.$t('contribute.step5.contactEmail'),
                    variant: _ctx.errors.contactEmail ? 'danger':'',
                    message: _ctx.errors.contactEmail
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_o_input, {
                        modelValue: _ctx.model.contactEmail,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.contactEmail) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label", "variant", "message"])
                ]),
                _: 1
              }),
              _createVNode(_component_FormGroup, {
                label: "contribute.step5.previousPublication",
                mandatory: false
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_o_field, {
                    label: _ctx.$t('contribute.step5.previousPublicationDoi'),
                    variant: _ctx.errors.previousPublicationDoi ? 'danger':'',
                    message: _ctx.errors.previousPublicationDoi
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_o_input, {
                        modelValue: _ctx.model.previousPublicationDoi,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.previousPublicationDoi) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label", "variant", "message"]),
                  _createVNode(_component_o_field, {
                    label: _ctx.$t('contribute.step5.previousPublicationComments'),
                    variant: _ctx.errors.previousPublicationComments ? 'danger':'',
                    message: _ctx.errors.previousPublicationComments
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_o_input, {
                        type: "textarea",
                        modelValue: _ctx.model.previousPublicationComments,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.previousPublicationComments) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label", "variant", "message"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_FormSection, {
            class: "column",
            title: "contribute.step5.scientistContextSection"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormGroup, { label: "contribute.step5.scientistContextSection" }, {
                default: _withCtx(() => [
                  _createVNode(_component_o_field, {
                    label: _ctx.$t('contribute.step5.project'),
                    variant: _ctx.errors.project ? 'danger':'',
                    message: _ctx.errors.project
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_o_input, {
                        modelValue: _ctx.model.project,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.project) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label", "variant", "message"]),
                  _createVNode(_component_o_field, {
                    label: _ctx.$t('contribute.step5.funding'),
                    variant: _ctx.errors.funding ? 'danger':'',
                    message: _ctx.errors.funding
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_o_input, {
                        modelValue: _ctx.model.funding,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.funding) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label", "variant", "message"]),
                  _createVNode(_component_o_field, {
                    label: _ctx.$t('contribute.step5.publications'),
                    variant: _ctx.errors.publications ? 'danger':'',
                    message: _ctx.errors.publications
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_o_input, {
                        modelValue: _ctx.model.publications,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.publications) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label", "variant", "message"]),
                  _createVNode(_component_o_field, {
                    label: _ctx.$t('contribute.step5.contextComments'),
                    variant: _ctx.errors.contextComments ? 'danger':'',
                    message: _ctx.errors.contextComments
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_o_input, {
                        type: "textarea",
                        modelValue: _ctx.model.contextComments,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.contextComments) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label", "variant", "message"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.model)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_o_button, {
            onClick: _ctx.cancel,
            inverted: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('forms.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_o_button, {
            onClick: _ctx.previous,
            outlined: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('forms.previous')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          (!_ctx.metadata?.compliant)
            ? (_openBlock(), _createBlock(_component_o_button, {
                key: 0,
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.saveModel(true))),
                disabled: !_ctx.authenticated || _ctx.savePending
              }, {
                default: _withCtx(() => [
                  (!_ctx.savePending)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('forms.saveAndNew')), 1))
                    : _createCommentVNode("", true),
                  (_ctx.savePending)
                    ? (_openBlock(), _createBlock(_component_Icon, {
                        key: 1,
                        name: "refresh",
                        rotate: ""
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true),
          _createVNode(_component_o_button, {
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.saveModel(false))),
            disabled: !_ctx.authenticated || _ctx.savePending
          }, {
            default: _withCtx(() => [
              (!_ctx.savePending)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('forms.saveAndClose')), 1))
                : _createCommentVNode("", true),
              (_ctx.savePending)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 1,
                    name: "refresh",
                    rotate: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_o_modal, {
      active: _ctx.timeSeriesConflictModalActive,
      "onUpdate:active": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.timeSeriesConflictModalActive) = $event)),
      scroll: "clip"
    }, {
      default: _withCtx(() => [
        (_ctx.timeSeriesConflictModalActive)
          ? (_openBlock(), _createBlock(_component_TimeSeriesUpdateConflict, {
              key: 0,
              fields: _ctx.timeSeriesConflictFields,
              prefix: 'contribute.step5',
              onCancel: _cache[9] || (_cache[9] = ($event: any) => (_ctx.timeSeriesConflictModalActive = false)),
              onOnlyContribution: _ctx.saveOnlyContribution,
              onSpreadToTimeSeries: _ctx.saveSpreadToTimeSeries
            }, null, 8, ["fields", "prefix", "onOnlyContribution", "onSpreadToTimeSeries"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}