import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "page-header big" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "search-page" }
const _hoisted_5 = { class: "filters-number" }
const _hoisted_6 = { class: "filters" }
const _hoisted_7 = {
  key: 2,
  class: "loading"
}
const _hoisted_8 = { class: "search-filters-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchFilters = _resolveComponent("SearchFilters")!
  const _component_SearchResults = _resolveComponent("SearchResults")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_o_button = _resolveComponent("o-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("search.title")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "filters-header",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showFilters && _ctx.showFilters(...args)))
      }, [
        _createTextVNode("Critères de recherche "),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.filtersNumber), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.showFiltersFlag || (!_ctx.foutaises.results && !_ctx.searchPending))
          ? (_openBlock(), _createBlock(_component_SearchFilters, {
              key: 0,
              filters: _ctx.latestFilters,
              searchStarted: _ctx.foutaises.results != undefined
            }, null, 8, ["filters", "searchStarted"]))
          : _createCommentVNode("", true),
        (!_ctx.showFiltersFlag && _ctx.foutaises.results && !_ctx.searchPending)
          ? (_openBlock(), _createBlock(_component_SearchResults, {
              key: 1,
              onPageSelected: _ctx.switchPage,
              results: _ctx.foutaises.results
            }, null, 8, ["onPageSelected", "results"]))
          : _createCommentVNode("", true),
        (_ctx.searchPending)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_Icon, {
                rotate: "",
                name: "refresh"
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_o_button, {
            inverted: "",
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('search.reset')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_o_button, {
            disabled: !_ctx.authenticated,
            onClick: _ctx.doSearch
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('search.applyFilters')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ])
    ])
  ]))
}