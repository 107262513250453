
import { Options, Vue } from 'vue-class-component'

import Icon from '@/components/Icon.vue'

@Options({
  props: {
    label: String
  },
  components: {
    Icon
  }
})
export default class Tooltip extends Vue {
}
