import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timeSeriesInfo" }
const _hoisted_2 = { class: "actionsHolder" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { href: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_o_button = _resolveComponent("o-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('contribute.step1.infoModal.title')), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('contribute.step1.infoModal.imageInfo')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('contribute.step1.infoModal.zipInfo')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, [
          _createVNode(_component_Icon, { name: "settings" }),
          _createTextVNode(_toDisplayString(_ctx.$t('contribute.step1.infoModal.actions')), 1)
        ]),
        _createElementVNode("a", _hoisted_4, [
          _createVNode(_component_Icon, { name: "file_upload" }),
          _createTextVNode(_toDisplayString(_ctx.$t('contribute.step1.infoModal.zipDownload')), 1)
        ])
      ])
    ]),
    _createVNode(_component_o_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Fermer")
      ]),
      _: 1
    })
  ]))
}