
import { Options, Vue } from 'vue-class-component'

import authService from '@/auth'
import validate from '@/validate'
import { success, warning, error } from '@/Toasts'

import contributionService from '@/services/ContributionService'
import ImagesolError from '@/model/ImagesolError'

import Icon from '@/components/Icon.vue'
import Tag from '@/components/Tag.vue'
import Tooltip from '@/components/Tooltip.vue'
import FormSection from '@/components/FormSection.vue'
import FormGroup from '@/components/FormGroup.vue'
import ContributeSteps from '@/components/ContributeSteps.vue'
import ContributeSkeleton from '@/components/ContributeSkeleton.vue'

import TimeSeriesUpdateConflict from '@/components/TimeSeriesUpdateConflict.vue'

import { ImageMetadata, ImageInfo, ImageDimension, ResolutionUnit, CaptureTechnology, TimeSeriesUpdateMethod } from '@/model/BackendDTOs'

import * as yup from 'yup';
import { BaseSchema } from 'yup';

@Options({
  props: {
    id: String
  },
  components: {
    ContributeSteps,
    ContributeSkeleton,
    FormSection,
    FormGroup,
    Icon,
    Tag,
    Tooltip,
    TimeSeriesUpdateConflict
  }
})
export default class Contribute2Technical extends Vue {

  id:string;

  dimensions:ImageDimension[] = ['TWO_D', 'THREE_D']
  resolutionUnits:ResolutionUnit[] = ['cm', 'mm', 'um']
  captureTechnologies:CaptureTechnology[] = ["MEDICAL_SCANNER", "INDUSTRIAL_SCANNER", "TWO_D_SCANNER", "MICROSCOPE", "OTHER"]

  authenticated = false
  savePending = false

  errors:{ [index: string]: string } = {};

  metadata:ImageMetadata|null = null;
  model:ImageInfo|null = null;

  timeSeriesConflictModalActive = false;
  timeSeriesConflictFields:string[]|null = null;

  validations:{ [index: string]: BaseSchema } = {
    acquisitionDateTime: yup.date().required('mandatory'),
    bitsPerPixel: yup.number().positive().integer().required('mandatory'),
    resolutionX: yup.number().positive().required('mandatory'),
    resolutionY: yup.number().positive().required('mandatory'),
    resolutionUnit: yup.string().required('mandatory'),
    imageSizeX: yup.number().positive().integer().required('mandatory'),
    imageSizeY: yup.number().positive().integer().required('mandatory'),
    dimension: yup.string().required('mandatory'),
    postAcquisitionTreatments: yup.boolean().required('mandatory')
  };

  validations3d:{ [index: string]: BaseSchema } = {
    resolutionZ: yup.number().positive().required('mandatory'),
    imageSizeZ: yup.number().positive().integer().required('mandatory')
  };

  created() {
    authService.getUser().then(u => {
      if (u) {
        this.authenticated = true
        contributionService.loadMetadata(this.id)
          .then(
            (data:ImageMetadata) => {
              this.metadata = data;
              this.model = this.metadata.imageInfo;
            },
            (e:Error) => {
              error(this.$oruga, e.message)
            }
          )
      } else {
        warning(this.$oruga, this.$t('contribute.step1.notAuthenticated'))
        this.$router.push('/')
      }
    });
  }

  cancel() {
    if (this.metadata?.compliant) {
      if (window.confirm(this.$t('contribute.confirmCancel'))) {
        this.$router.push('/')
      }
    } else {
      if (window.confirm(this.$t('contribute.confirmCancelAndDelete'))) {
        contributionService.deleteMetadata(this.id)
          .then(
            () => {
              success(this.$oruga, this.$t('contribute.dropped'));
              this.$router.push('/')
            },
            (e:Error) => {
              error(this.$oruga, "Impossible de supprimer votre contribution: " + e.message)
            }
          )
      }
    }
  }

  previous() {
    this.$router.push(`/contribute/${this.id}`)
  }

  save() {
    this.save0();
  }

  saveOnlyContribution() {
    this.timeSeriesConflictModalActive = false;
    this.save0('CONTRIBUTION_ONLY');
  }

  saveSpreadToTimeSeries() {
    this.timeSeriesConflictModalActive = false;
    this.save0('TIME_SERIES');
  }

  save0(tsum?:TimeSeriesUpdateMethod) {
    const validations = this.model?.dimension === 'THREE_D'
      ? { ...this.validations, ...this.validations3d }
      : { ...this.validations };
    this.errors = validate(this.model, validations, this.$t);
    if (Object.keys(this.errors).length === 0) {
      this.savePending = true;
      contributionService.saveImageInfo(this.id, this.model!, tsum)
        .then(
          () => {
            this.savePending = false;
            this.$router.push(`/contribute/${this.id}/sample`)
          },
          (e:ImagesolError) => {
            this.savePending = false;
            if (e.type && e.type === 'TimeSeriesUpdateDetectedException') {
              this.timeSeriesConflictFields = e.tsuFields!;
              this.timeSeriesConflictModalActive = true;
            } else if (e.type && e.type === 'InvalidFormatException') {
              this.errors[e.invalidFormatFieldId!] = this.$t('contribute.invalidFormatField');
              error(this.$oruga, this.$t('contribute.invalidFormat'))
            } else if (e.type && e.type === 'TimeSeriesNotFoundException') {
              this.errors.timeSeriesId = this.$t('contribute.step2.timeSeriesIdNotFound');
              error(this.$oruga, this.$t('contribute.step2.timeSeriesIdNotFoundLong'))
            } else {
              error(this.$oruga, "Une erreur est survenue pendant la mise à jour de la contribution : " + e.type)
            }
          }
        )
    } else {
      document.getElementById("formTop")?.scrollIntoView({
        behavior: "smooth"
      })
    }
  }

}
