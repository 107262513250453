import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "faq" }
const _hoisted_2 = { class: "page-header big" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "faq-page" }
const _hoisted_5 = {
  key: 0,
  class: "question"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("faq.title")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
        return _createElementVNode("div", {
          key: 'faq' + i
        }, [
          (_ctx.$t('faq.question' + i) !== ('faq.question' + i))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t("faq.question" + i)), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("faq.answer" + i)), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      }), 64))
    ])
  ]))
}