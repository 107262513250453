import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/dessinNOK.svg'
import _imports_1 from '@/assets/img/dessinOK.svg'


const _hoisted_1 = { class: "contribute" }
const _hoisted_2 = { class: "page-header big" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 0,
  class: "columns"
}
const _hoisted_5 = { class: "column" }
const _hoisted_6 = { class: "block" }
const _hoisted_7 = {
  key: 0,
  class: "file-name"
}
const _hoisted_8 = {
  key: 1,
  class: "file-name"
}
const _hoisted_9 = ["value"]
const _hoisted_10 = {
  key: 0,
  class: "timeSeriesFileInfo"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 2,
  class: "columns"
}
const _hoisted_13 = { class: "column" }
const _hoisted_14 = {
  key: 0,
  src: _imports_0
}
const _hoisted_15 = {
  key: 1,
  src: _imports_1
}
const _hoisted_16 = { class: "column" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "error" }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "contribute-buttons" }
const _hoisted_21 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ContributeSteps = _resolveComponent("ContributeSteps")!
  const _component_o_radio = _resolveComponent("o-radio")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_o_input = _resolveComponent("o-input")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_FormSection = _resolveComponent("FormSection")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_o_upload = _resolveComponent("o-upload")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_UploadRow = _resolveComponent("UploadRow")!
  const _component_TimeSeriesUpdateConflict = _resolveComponent("TimeSeriesUpdateConflict")!
  const _component_o_modal = _resolveComponent("o-modal")!
  const _component_TimeSeriesInfo = _resolveComponent("TimeSeriesInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.creationComplete ? _ctx.$t("contribute.updateTitle") : _ctx.$t("contribute.title")), 1),
      (_ctx.summary)
        ? (_openBlock(), _createBlock(_component_Tag, {
            key: 0,
            text: _ctx.summary
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ContributeSteps, {
      step: 1,
      id: _ctx.copiedId,
      compliant: _ctx.creationComplete
    }, null, 8, ["id", "compliant"]),
    (_ctx.uploadStatus === 'NONE')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_FormSection, { title: "contribute.step1.timeSeries" }, {
              default: _withCtx(() => [
                _createVNode(_component_FormGroup, { label: "contribute.step1.timeSeriesInfo" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step1.timeSeriesQuestion'),
                      variant: _ctx.errors.timeSeries ? 'danger':'',
                      message: _ctx.errors.timeSeries,
                      class: _normalizeClass('mandatory_' + _ctx.$i18n.locale)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_o_radio, {
                            modelValue: _ctx.timeSeriesType,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.timeSeriesType) = $event)),
                            "native-value": "NEW"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('contribute.step1.new')), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _createVNode(_component_o_radio, {
                            modelValue: _ctx.timeSeriesType,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.timeSeriesType) = $event)),
                            "native-value": "EXISTING"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('contribute.step1.existing')), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _createVNode(_component_o_radio, {
                            modelValue: _ctx.timeSeriesType,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.timeSeriesType) = $event)),
                            "native-value": "NO"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('contribute.step1.no')), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    (_ctx.timeSeriesType === 'EXISTING')
                      ? (_openBlock(), _createBlock(_component_o_field, {
                          key: 0,
                          label: _ctx.$t('contribute.step2.timeSeriesId'),
                          variant: _ctx.errors.timeSeriesId ? 'danger':'',
                          message: _ctx.errors.timeSeriesId,
                          class: _normalizeClass('mandatory_' + _ctx.$i18n.locale)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_input, {
                              modelValue: _ctx.timeSeriesId,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.timeSeriesId) = $event)),
                              expanded: "",
                              placeholder: "ABC-123"
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_Tooltip, {
                              label: _ctx.$t('contribute.step2.timeSeriesIdTooltip')
                            }, null, 8, ["label"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message", "class"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_FormSection, { title: "contribute.step1.fileUpload" }, {
              default: _withCtx(() => [
                _createVNode(_component_FormGroup, { label: "contribute.step1.file" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      class: _normalizeClass(["file", 'mandatory_' + _ctx.$i18n.locale]),
                      label: _ctx.$t('contribute.step1.file'),
                      variant: _ctx.errors.file ? 'danger':'',
                      message: _ctx.errors.file
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_upload, {
                          modelValue: _ctx.file,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.file) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_button, {
                              tag: "a",
                              disabled: _ctx.fileOnServer != null
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('contribute.step1.browse')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    (_ctx.file)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.file.name) + " (" + _toDisplayString(_ctx.formatFileSize(_ctx.file.size)) + ") ", 1))
                      : _createCommentVNode("", true),
                    (_ctx.id && _ctx.fileOnServer)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.fileOnServer.fileName) + " (" + _toDisplayString(_ctx.formatFileSize(_ctx.fileOnServer.fileSize)) + ") ", 1))
                      : _createCommentVNode("", true),
                    (_ctx.uploadPercent)
                      ? (_openBlock(), _createElementBlock("progress", {
                          key: 2,
                          class: "progress is-small",
                          value: _ctx.uploadPercent,
                          max: "100"
                        }, _toDisplayString(_ctx.uploadPercent) + "%", 9, _hoisted_9))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (_ctx.timeSeriesType === 'EXISTING' || _ctx.timeSeriesType === 'NEW')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_Icon, { name: "tips_and_updates" }),
                      _createElementVNode("div", null, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('contribute.step1.uploadTip')), 1),
                        _createVNode(_component_o_button, {
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.timeSeriesFileInfoModalActive=true)),
                          class: "modele"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('contribute.step1.seeExample')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_FormSection, {
            class: "column",
            title: "contribute.step1.imageInfo"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormGroup, { label: "contribute.step1.imageInfo" }, {
                default: _withCtx(() => [
                  _createVNode(_component_o_field, {
                    label: _ctx.$t('contribute.step1.summary'),
                    variant: _ctx.errors.summary ? 'danger':'',
                    message: _ctx.errors.summary,
                    class: _normalizeClass('mandatory_' + _ctx.$i18n.locale)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_o_input, {
                        modelValue: _ctx.summary,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.summary) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label", "variant", "message", "class"]),
                  _createVNode(_component_o_field, {
                    label: _ctx.$t('contribute.step1.description'),
                    variant: _ctx.errors.description ? 'danger':'',
                    message: _ctx.errors.description,
                    class: _normalizeClass('mandatory_' + _ctx.$i18n.locale)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_o_input, {
                        maxlength: "200",
                        type: "textarea",
                        modelValue: _ctx.description,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.description) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label", "variant", "message", "class"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.uploadStatus === 'UPLOADING')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_UploadRow, {
            contributionTitle: _ctx.summary,
            percent: _ctx.uploadPercent,
            complete: false
          }, null, 8, ["contributionTitle", "percent"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.uploadStatus === 'RESULTS')
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            (_ctx.uploadResponse)
              ? (_openBlock(), _createElementBlock("img", _hoisted_14))
              : (_openBlock(), _createElementBlock("img", _hoisted_15))
          ]),
          _createElementVNode("div", _hoisted_16, [
            (_ctx.uploadResponse)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createVNode(_component_FormSection, { title: "contribute.step1.fileVerification" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormGroup, { label: "contribute.step1.informations" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('contribute.step1.fileVerificationKO')), 1),
                          _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.uploadResponse.error) + " " + _toDisplayString(_ctx.uploadResponse.cause), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_o_button, { onClick: _ctx.returnToForm }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('contribute.step1.returnToForm')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createVNode(_component_FormSection, { title: "contribute.step1.fileVerification" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormGroup, { label: "contribute.step1.informations" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('contribute.step1.fileVerificationOK')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_20, [
      _createVNode(_component_o_button, {
        inverted: "",
        onClick: _ctx.cancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('forms.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_o_button, {
        onClick: _ctx.save,
        disabled: !_ctx.authenticated || _ctx.savePending
      }, {
        default: _withCtx(() => [
          (!_ctx.savePending)
            ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.$t('forms.next')), 1))
            : _createCommentVNode("", true),
          (_ctx.savePending)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 1,
                name: "refresh",
                rotate: ""
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ]),
    _createVNode(_component_o_modal, {
      active: _ctx.timeSeriesConflictModalActive,
      "onUpdate:active": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.timeSeriesConflictModalActive) = $event)),
      scroll: "clip"
    }, {
      default: _withCtx(() => [
        (_ctx.timeSeriesConflictModalActive)
          ? (_openBlock(), _createBlock(_component_TimeSeriesUpdateConflict, {
              key: 0,
              fields: _ctx.timeSeriesConflictFields,
              prefix: 'contribute.step1',
              onCancel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.timeSeriesConflictModalActive = false)),
              onOnlyContribution: _ctx.saveOnlyContribution,
              onSpreadToTimeSeries: _ctx.saveSpreadToTimeSeries
            }, null, 8, ["fields", "prefix", "onOnlyContribution", "onSpreadToTimeSeries"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["active"]),
    _createVNode(_component_o_modal, {
      active: _ctx.timeSeriesFileInfoModalActive,
      "onUpdate:active": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.timeSeriesFileInfoModalActive) = $event)),
      scroll: "clip"
    }, {
      default: _withCtx(() => [
        (_ctx.timeSeriesFileInfoModalActive)
          ? (_openBlock(), _createBlock(_component_TimeSeriesInfo, {
              key: 0,
              onCancel: _cache[10] || (_cache[10] = ($event: any) => (_ctx.timeSeriesFileInfoModalActive = false))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}