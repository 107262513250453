
import { Vue } from 'vue-class-component'

export default class App extends Vue {

  layout():string {
    let layout;
    if (this.$route.name) {
      layout = (this.$route.meta.layout || 'default') + '-layout';
    } else {
      // Pendant le chargement initial de la page, on évite de renvoyer le layout complet
      layout = 'raw-layout';
    }
    return layout;
  }

}
