
import { Vue, Options } from 'vue-class-component'
import Icon from '@/components/Icon.vue'

@Options({
  components: {
    Icon
  }
})
export default class FAQ extends Vue {
}
