
import Toast from '@/components/Toast.vue'

function create ($oruga:any, type:string, message:string, duration:number, iconName:string):void {
  $oruga.notification.open({
    component: Toast,
    props: {
      msg: message,
      iconName: iconName
    },
    rootClass: 'toast-notification',
    position: 'bottom',
    duration: duration * 1000,
    variant: type
  });
}

export function success ($oruga:any, message:string):void {
  create($oruga, 'success', message, 15, "check_circle_outline")
}

export function info ($oruga:any, message:string):void {
  create($oruga, 'info', message, 5, "info")
}

export function warning ($oruga:any, message:string):void {
  create($oruga, 'warning', message, 20, "warning")
}

export function error ($oruga:any, message:string):void {
  create($oruga, 'danger', message, 30, "error")
}
