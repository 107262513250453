import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "add-collaborater-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_autocomplete = _resolveComponent("o-autocomplete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('collaboraters.title')), 1),
    _createElementVNode("span", null, _toDisplayString(_ctx.$t('collaboraters.prompt')), 1),
    _createVNode(_component_o_autocomplete, {
      "open-on-focus": "",
      data: _ctx.list,
      field: "fullName",
      scroll: "clip",
      onSelect: _cache[0] || (_cache[0] = option => _ctx.collaboraterSelected(option)),
      onTyping: _ctx.getAsyncData
    }, null, 8, ["data", "onTyping"])
  ]))
}