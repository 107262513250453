
import { Options, Vue } from 'vue-class-component'

import authService from '@/auth'
import validate from '@/validate'
import { success, warning, error } from '@/Toasts'

import contributionService from '@/services/ContributionService'
import ImagesolError from '@/model/ImagesolError'

import Tag from '@/components/Tag.vue'
import Icon from '@/components/Icon.vue'
import FormSection from '@/components/FormSection.vue'
import FormGroup from '@/components/FormGroup.vue'
import ContributeSteps from '@/components/ContributeSteps.vue'

import TimeSeriesUpdateConflict from '@/components/TimeSeriesUpdateConflict.vue'
import TimeSeriesInfo from '@/components/TimeSeriesInfo.vue'

import { ImageBootstrap, ImageMetadata, ImageMetadataFile, TimeSeriesUpdateMethod } from '@/model/BackendDTOs'
import StartUploadEvent from '@/model/StartUploadEvent'

import * as yup from 'yup';
import { BaseSchema } from 'yup';

import events from '@/events'

@Options({
  props: {
    id: String,
    file: File
  },
  components: {
    ContributeSteps,
    FormSection,
    FormGroup,
    Icon,
    Tag,
    TimeSeriesUpdateConflict,
    TimeSeriesInfo
  }
})
export default class ContributeZipResult extends Vue {

  id:string|undefined;
  file:File|undefined;
  creationComplete = false;

  authenticated = false
  savePending = false

  summary:string|null = null;
  description:string|null = null;

  fileOnServer:ImageMetadataFile|null = null;

  timeSeriesType: "NEW" | "EXISTING" | "NO" | null = null;

  timeSeriesId: string | null = null;
  timeSeriesFileInfoModalActive = false;

  uploadPercent:number|null = null;

  errors:{ [index: string]: string } = {};

  timeSeriesConflictModalActive = false;
  timeSeriesConflictFields:string[]|null = null;

  validations:{ [index: string]: BaseSchema } = {
    summary: yup.string().nullable().trim().required('mandatory').min(5, 'Le résumé ne peut faire moins de 5 caractères'),
    description: yup.string().nullable().trim().required('mandatory')
  };

  created() {

    console.log(this.file!.name);
    authService.getUser().then(u => {
      if (u) {
        this.authenticated = true

        if (this.id) {
          contributionService.loadMetadata(this.id)
            .then(
              (data:ImageMetadata) => {
                this.creationComplete = data.compliant;
                this.summary = data.imageBootstrap.summary;
                this.description = data.imageBootstrap.description || null;
                this.fileOnServer = data.file || null;
                this.timeSeriesId = data.imageBootstrap.timeSeriesId || null;
                this.timeSeriesType = data.imageBootstrap.timeSeriesId ? "EXISTING" : "NO";
              },
              (e:Error) => {
                error(this.$oruga, e.message)
              }
            )
        }
      } else {
        warning(this.$oruga, this.$t('contribute.step1.notAuthenticated'))
      }
    });
  }

  cancel() {
    if (this.id) {
      if (this.creationComplete) {
        if (window.confirm(this.$t('contribute.confirmCancel'))) {
          this.$router.push('/')
        }
      } else {
        if (window.confirm(this.$t('contribute.confirmCancelAndDelete'))) {
          contributionService.deleteMetadata(this.id)
            .then(
              () => {
                success(this.$oruga, this.$t('contribute.dropped'));
                this.$router.push('/')
              },
              (e:Error) => {
                error(this.$oruga, "Impossible de supprimer votre contribution: " + e.message)
              }
            )
        }
      }
    } else {
      this.$router.push('/')
    }
  }

  save() {
    this.save0();
  }

  saveOnlyContribution() {
    this.timeSeriesConflictModalActive = false;
    this.save0('CONTRIBUTION_ONLY');
  }

  saveSpreadToTimeSeries() {
    this.timeSeriesConflictModalActive = false;
    this.save0('TIME_SERIES');
  }

  save0(tsum?:TimeSeriesUpdateMethod) {
    const model:ImageBootstrap = {
      compliant: false,
      summary: this.summary!,
      description: this.description!,
      timeSeriesId: this.timeSeriesId!,
      timeSeries: this.timeSeriesType !== "NO"
    }
    this.errors = validate(model, this.validations, this.$t);
    if ((!this.fileOnServer || this.fileOnServer == null) && (!this.file || this.file == null)) {
      this.errors.file = this.$t('forms.errors.mandatory')
    }
    if (this.id) {
      if (Object.keys(this.errors).length === 0) {
        this.savePending = true;
        contributionService.saveImageBootstrap(this.id, model, tsum)
          .then(
            () => {
              this.savePending = false;
              if ((!this.fileOnServer || this.fileOnServer == null) && this.file) {
                this.startFileUpload(this.id!)
              } else {
                this.$router.push(`/contribute/${this.id}/technical`)
              }
            },
            (e:ImagesolError) => {
              this.savePending = false;
              if (e.type && e.type === 'TimeSeriesUpdateDetectedException') {
                this.timeSeriesConflictFields = e.tsuFields!;
                this.timeSeriesConflictModalActive = true;
              } else {
                error(this.$oruga, "Une erreur est survenue pendant la mise à jour de la contribution : " + e.type)
              }
            }
          )

      }
    } else {
      if (Object.keys(this.errors).length === 0) {
        this.savePending = true;
        contributionService.createImageBootstrap(model)
          .then(
            (newId) => {
              this.savePending = false;
              this.startFileUpload(newId)
            },
            (e:Error) => {
              this.savePending = false;
              error(this.$oruga, "Une erreur est survenue pendant la création de la contribution : " + e.message)
            }
          )
      }
    }
  }

  startFileUpload(id:string) {

    const uploadFile:StartUploadEvent = {
      contributionId: id,
      contributionTitle: this.summary!,
      file: this.file!
    }

    if (this.file!.name.endsWith(".zip")) {
      // upload
      this.$router.push(`/contribute/${id}/zip-result`)
    } else {
      events.emit('start-upload', uploadFile)
      this.$router.push(`/contribute/${id}/technical`)
    }
  }

  formatFileSize(octets:number):string {
    if (octets < 1024) {
      return octets + " o"
    }
    const kiloOctets = octets / 1024;
    if (kiloOctets < 1024) {
      return (Math.floor(kiloOctets * 10) / 10) + " Ko"
    }

    const megaOctets = kiloOctets / 1024;
    if (megaOctets < 1024) {
      return (Math.floor(megaOctets * 10) / 10) + " Mo"
    }

    const gigaOctets = megaOctets / 1024;
    return (Math.floor(gigaOctets * 10) / 10) + " Go"
  }

}
