import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tsuc" }
const _hoisted_2 = { class: "tsuc-content" }
const _hoisted_3 = { class: "fieldsList" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "explanation" }
const _hoisted_7 = { class: "wdyt" }
const _hoisted_8 = { class: "wdyt-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_o_button = _resolveComponent("o-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('contribute.tsuc.title')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.fields.length == 1)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('contribute.tsuc.singleField')), 1))
          : _createCommentVNode("", true),
        (_ctx.fields.length > 1)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('contribute.tsuc.fields')), 1))
          : _createCommentVNode("", true),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (fieldName) => {
            return (_openBlock(), _createElementBlock("li", { key: fieldName }, _toDisplayString(_ctx.displayableFieldName(fieldName)), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Icon, { name: "help_outline" }),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('contribute.tsuc.explanation')), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_o_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel'))),
            inverted: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('contribute.tsuc.cancel')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_o_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onlyContribution')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('contribute.tsuc.onlyContribution')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_o_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('spreadToTimeSeries')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('contribute.tsuc.spreadToTimeSeries')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}