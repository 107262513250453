
import { Vue, Options } from 'vue-class-component'
import fetchPost from '@/fetchPost'
import authService from '@/auth'
import { ImageMetadata, PaginationResult, SearchForm } from "@/model/BackendDTOs"
import { warning, error } from '@/Toasts'
import SearchFilters from '@/components/SearchFilters.vue'
import SearchResults from '@/components/SearchResults.vue'
import Icon from '@/components/Icon.vue'

@Options({
  components: {
    Icon,
    SearchFilters,
    SearchResults
  },
  watch: {
    latestFilters: [
      {
        handler: 'computeFiltersNumber',
        deep: true
      }
    ]
  }
})
export default class Search extends Vue {

  authenticated = false

  showFiltersFlag = true

  foutaises:{results?:PaginationResult<ImageMetadata>} = {}
  searchPending = false
  pageSize = 10;

  latestFilters = new SearchForm()

  filtersNumber = 0

  created() {
    authService.getUser().then(u => {
      if (u) {
        this.authenticated = true
      } else {
        warning(this.$oruga, this.$t('search.notAuthenticated'))
      }
    })
  }

  cancel() {
    this.latestFilters = new SearchForm();
    this.showFiltersFlag = true;
  }

  showFilters() {
    if (this.showFiltersFlag) {
      this.showFiltersFlag = false;
    } else {
      this.showFiltersFlag = true;
    }
  }

  doSearch () {
    // this.latestFilters = filters;
    /* if (this.latestFilters.compliant === false) {
      delete this.latestFilters.compliant;
    } */
    this.doSearch0(this.latestFilters, 0, this.pageSize)
  }

  switchPage (pageNumber:number) {
    this.doSearch0(this.latestFilters, pageNumber, this.pageSize)
  }

  doSearch0 (filters:SearchForm, pageNumber:number, pageSize: number) {
    this.showFiltersFlag = false
    this.searchPending = true
    fetchPost(`/v1/imagesol/search`, { ...filters, pageNumber, pageSize })
      .then(
        (res) => {
          res.json().then((pr:PaginationResult<ImageMetadata>) => {
            this.foutaises.results = pr
            this.searchPending = false
          })
        },
        (e) => {
          error(this.$oruga, `Une erreur est survenue pendant la recherche: ${e}`)
          this.searchPending = false
        })
  }

  computeFiltersNumber() {
    this.filtersNumber = 0;

    let property: keyof typeof this.latestFilters

    for (property in this.latestFilters) {
      if (this.latestFilters[property] !== undefined && this.latestFilters[property] !== "" && this.latestFilters[property] !== null) {
        this.filtersNumber = this.filtersNumber + 1
      }
    }
  }

}
