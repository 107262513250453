
import { Options, Vue } from 'vue-class-component'

import Icon from '@/components/Icon.vue'

@Options({
  props: {
    fields: Array,
    prefix: String
  },
  components: {
    Icon
  }
})
export default class TimeSeriesUpdateConflict extends Vue {
  fields:string[];
  prefix:string;

  displayableFieldName(fieldName:string):string {
      const i18nKey = `${this.prefix}.${fieldName}`;
      const i18nKeyFull = `${i18nKey}Full`;
      let result = this.$t(i18nKeyFull);
      if (result === i18nKeyFull) { // La traduction est égale à la clé
           // Cela signifie que la traduction n'existe pas
           result = this.$t(i18nKey);
      }
      return result;
  }
}
