import type { LocaleMessage } from '@intlify/core-base';
import { createI18n, VueMessageType } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): { [x: string]: LocaleMessage<VueMessageType>; } {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: { [x: string]: LocaleMessage<VueMessageType>; } = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

// On charge les fichiers de traductions
const localMessages = loadLocaleMessages();

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  // warnHtmlInMessage: 'off', // J'ai essayé pas mal de trucs pour pouvoir mettre du HTML dans les trad, mais en vain : https://stackoverflow.com/questions/69795845/vue-3-eslint-warning-intlify-detected-html
  // warnHtmlMessage: false,
  messages: localMessages
})
