
import { Options, Vue } from 'vue-class-component'

import authService from '@/auth'
import { success, warning, error } from '@/Toasts'

import contributionService from '@/services/ContributionService'

import Tag from '@/components/Tag.vue'
import Icon from '@/components/Icon.vue'
import MetadataBlock from '@/components/MetadataBlock.vue'
import MetadataRow from '@/components/MetadataRow.vue'
import AddCollaborater from '@/components/AddCollaborater.vue'

import { ImageMetadata } from '@/model/BackendDTOs'

@Options({
  props: {
    id: String
  },
  components: {
    Icon,
    Tag,
    MetadataBlock,
    MetadataRow,
    AddCollaborater
  },
  watch: {
    "$route.params.id"(val) {
      // call the method which loads your initial state
      this.intialLoad(this.$route.params.id);
    }
  }
})
export default class Read extends Vue {

  id:string;
  selected = '1';
  previewUrl = '';

  metadata:ImageMetadata|null = null;

  addCollaboraterModalActive = false;

  created() {
    this.intialLoad(this.id);
  }

  intialLoad(metadataId:string) {
    // Sécurité pour éviter un chargement quand on quitte la page (cf. watch)
    if (metadataId) {
      this.previewUrl = '';
      authService.getUser().then(u => {
        if (u) {
          contributionService.loadMetadata(metadataId)
            .then(
              (data:ImageMetadata) => {
                this.metadata = data;
                if (this.metadata.mayHavePreview) {
                  this.previewUrl = contributionService.getPreviewUrl(data);
                }
              },
              (e:Error) => {
                error(this.$oruga, e.message)
              }
            )
        } else {
          warning(this.$oruga, this.$t('read.notAuthenticated'))
        }
      });
    }
  }

  copyToClipboard() {
    const newInput = document.body.appendChild(document.createElement("input"));
    newInput.value = window.location.href;
    newInput.focus();
    newInput.select();
    document.execCommand('copy');
    newInput.parentNode!.removeChild(newInput);
    success(this.$oruga, this.$t('read.copied'))
  }

  formatDateTime(input:Date):string {
    const locale = this.$i18n.locale;
    const dateString = this.formatDate(input);
    const hourString = input.toLocaleTimeString(locale, { hour: "2-digit", minute: "2-digit" });
    const atString = this.$t('read.at');
    return `${dateString} ${atString} ${hourString}`;
  }

  formatDate(input:Date):string {
    const locale = this.$i18n.locale;
    const dateString = input.toLocaleDateString(locale, { weekday: "long", year: 'numeric', month: 'long', day: 'numeric' });
    return dateString;
  }

  blockClicked(idClicked:any) {
    this.selected = idClicked;
  }

  formatFileSize(octets:number):string {
    if (octets < 1024) {
      return octets + " o"
    }
    const kiloOctets = octets / 1024;
    if (kiloOctets < 1024) {
      return (Math.floor(kiloOctets * 10) / 10) + " Ko"
    }

    const megaOctets = kiloOctets / 1024;
    if (megaOctets < 1024) {
      return (Math.floor(megaOctets * 10) / 10) + " Mo"
    }

    const gigaOctets = megaOctets / 1024;
    return (Math.floor(gigaOctets * 10) / 10) + " Go"
  }

  appendResolutionUnit(value:any):string {
    const unit = this.$t('enums.ResolutionUnit.' + this.metadata!.imageInfo.resolutionUnit)
    return `${value} ${unit}`;
  }

  appendCm(value:any):string {
    return `${value} cm`;
  }

  appendPercent(value:any):string {
    return `${value}%`;
  }

  updateContribution() {
    this.$router.push(`/contribute/${this.id}`)
  }

  deleteContribution() {
    if (window.confirm(this.$t('read.confirmDelete'))) {
      contributionService.deleteMetadata(this.id)
        .then(
          () => {
            success(this.$oruga, this.$t('contribute.dropped'));
            this.$router.push('/')
          },
          (e:Error) => {
            error(this.$oruga, "Impossible de supprimer votre contribution: " + e.message)
          }
        )
    }
  }

  downloadContribution() {
    contributionService.getDownloadUrl(this.metadata!)
    .then(
      (downloadUrl) => {
        window.location.href = downloadUrl;
      },
      (err) => {
        console.error("Unable to get download URL", err);
        error(this.$oruga, this.$t('read.cannotDownload'));
      })
  }

  addCollaborater() {
    this.addCollaboraterModalActive = true;
  }

  newOneAdded(addedCollaborater:any) {
    this.addCollaboraterModalActive = false;
    this.metadata!.collaboraters.push(addedCollaborater);
  }

  deleteCollaborater(userId:string) {
    contributionService.deleteCollaborater(userId, this.id)
      .then(
        () => {
          success(this.$oruga, this.$t('collaboraters.deleted'));
          let foundAtIndex = -1;
          for (let i = 0; i < this.metadata!.collaboraters.length; i++) {
              const someCollab = this.metadata!.collaboraters[i];
              if (userId === someCollab.userId) {
                  foundAtIndex = i;
              }
          }
          if (foundAtIndex >= 0) {
              this.metadata!.collaboraters.splice(foundAtIndex, 1);
          }
        },
        (eee) => {
          console.error('Unable to delete collaborater', eee);
          error(this.$oruga, `Unable to delete collaborater: ${eee}`);
        }
      );
  }

  duplicate() {
    contributionService.duplicateContribution(this.id)
      .then(
        (newId:string) => {
          success(this.$oruga, this.$t('read.duplicated'));
          this.$router.push(`/contribute/${newId}`)
        },
        (eee) => {
          console.error('Unable to delete collaborater', eee);
          error(this.$oruga, `Unable to delete collaborater: ${eee}`);
        }
      );
  }

  turnIntoTimeSeries() {
    contributionService.turnIntoTimeSeries(this.id)
      .then(
        (timeSeriesId:string) => {
          success(this.$oruga, this.$t('read.turnedIntoTimeSerie') + ' : ' + timeSeriesId);
          this.duplicate();
        },
        (eee) => {
          console.error('Unable to turn into time series', eee);
          error(this.$oruga, `Unable to turn into time series: ${eee}`);
        }
      );
  }

  addIntoTimeSerie() {
    contributionService.duplicateContribution(this.id)
      .then(
        (newId:string) => {
          success(this.$oruga, this.$t('read.duplicatedForTimeSerie'));
          this.$router.push(`/contribute/${newId}`)
        },
        (eee) => {
          console.error('Unable to duplicate into time series', eee);
          error(this.$oruga, `Unable to duplicate into time series: ${eee}`);
        }
      );
  }

  navigatePrevious() {
    if (this.metadata?.previousMetadataId) {
      this.$router.push(`/read/${this.metadata?.previousMetadataId}`);
      // window.location.href = `${document.location.origin}/read/${this.metadata?.previousMetadataId}`;
    }
  }

  navigateNext() {
    if (this.metadata?.nextMetadataId) {
      this.$router.push(`/read/${this.metadata?.nextMetadataId}`);
      // window.location.href = `${document.location.origin}/read/${this.metadata?.nextMetadataId}`;
    }
  }

  publishMetadata() {
    contributionService.publishMetadata(this.id)
      .then(
        () => {
          success(this.$oruga, this.$t('read.metadataPusblished'));
          // On ne peut plus republier, donc on anticipe en changeant le flag
          this.metadata!.metadataPublishable = false;
        },
        (eee) => {
          console.error('Unable to publish metadata', eee);
          error(this.$oruga, `Unable to publish metadata: ${eee}`);
        }
      );
  }

  publishFile() {
    contributionService.publishFile(this.metadata!)
      .then(
        () => {
          success(this.$oruga, this.$t('read.filePusblished'));
          // On ne peut plus republier, donc on anticipe en changeant le flag
          this.metadata!.metadataPublishable = false;
          this.metadata!.filePublishable = false;
        },
        (eee) => {
          console.error('Unable to publish file', eee);
          error(this.$oruga, `Unable to publish file: ${eee}`);
        }
      );
  }

}
