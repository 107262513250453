
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    step: Number,
    id: String,
    compliant: Boolean
  },
  watch: {
    "foutaises.activeStep": [
      {
        handler: 'onStepChanged'
      }
    ]
  }
})
export default class ContributeSteps extends Vue {
  step: number;
  id: string;
  compliant: boolean;
  foutaises: {activeStep?: number} = {};

  created() {
    this.foutaises.activeStep = this.step;
  }

  onStepChanged(newValue:number, oldValue:number) {
    // Si on a une ancienne valeur c'est qu'il ne s'agit pas du chargement initial
    if (oldValue && this.id) {
      switch (newValue) {
        case 1:
          this.$router.push(`/contribute/${this.id}`)
          break;
        case 2:
          this.$router.push(`/contribute/${this.id}/technical`)
          break;
        case 3:
          this.$router.push(`/contribute/${this.id}/sample`)
          break;
        case 4:
          this.$router.push(`/contribute/${this.id}/instructive`)
          break;
        case 5:
          this.$router.push(`/contribute/${this.id}/administrative`)
          break;
        default:
          break;
      }
    }
  }

}
