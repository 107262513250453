import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-filters" }
const _hoisted_2 = { class: "search-steps-selection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchStep1Bootstrap = _resolveComponent("SearchStep1Bootstrap")!
  const _component_SearchStep2Technical = _resolveComponent("SearchStep2Technical")!
  const _component_SearchStep3Sample = _resolveComponent("SearchStep3Sample")!
  const _component_SearchStep4Instructive = _resolveComponent("SearchStep4Instructive")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedStep=1)),
        class: _normalizeClass(["step", { selected: _ctx.selectedStep === 1, hasFilter: _ctx.hasStep1Filters()}])
      }, "Entête", 2),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedStep=2)),
        class: _normalizeClass(["step", { selected: _ctx.selectedStep === 2, hasFilter: _ctx.hasStep2Filters()}])
      }, "Données techniques", 2),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedStep=3)),
        class: _normalizeClass(["step", { selected: _ctx.selectedStep === 3, hasFilter: _ctx.hasStep3Filters()}])
      }, "Données descriptives : échantillon", 2),
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedStep=4)),
        class: _normalizeClass(["step", { selected: _ctx.selectedStep === 4, hasFilter: _ctx.hasStep4Filters()}])
      }, "Données descriptives : contexte", 2)
    ]),
    (_ctx.selectedStep === 1)
      ? (_openBlock(), _createBlock(_component_SearchStep1Bootstrap, {
          key: 0,
          filters: _ctx.filters
        }, null, 8, ["filters"]))
      : _createCommentVNode("", true),
    (_ctx.selectedStep === 2)
      ? (_openBlock(), _createBlock(_component_SearchStep2Technical, {
          key: 1,
          filters: _ctx.filters
        }, null, 8, ["filters"]))
      : _createCommentVNode("", true),
    (_ctx.selectedStep === 3)
      ? (_openBlock(), _createBlock(_component_SearchStep3Sample, {
          key: 2,
          filters: _ctx.filters
        }, null, 8, ["filters"]))
      : _createCommentVNode("", true),
    (_ctx.selectedStep === 4)
      ? (_openBlock(), _createBlock(_component_SearchStep4Instructive, {
          key: 3,
          filters: _ctx.filters
        }, null, 8, ["filters"]))
      : _createCommentVNode("", true)
  ]))
}