import authService from '@/auth'

import config from '@/Config'
const BACKEND = config.getBackend();

export default async (uri: string, body: any = {}, params?: any):Promise<Response> => {
  let user = await authService.getUser()
  if (user === null) {
    throw new Error('user not logged in')
  }
  if (user.expired) {
    console.warn("Token expiré on en demande un nouveau")
    user = await authService.renewToken()
  }

  const headers = new Headers({
    Accept: 'application/json',
    "Content-Type": 'application/json',
    Authorization: 'Bearer ' + user!.access_token
  })

  const init: RequestInit = {
    method: 'PUT',
    headers: headers
  }

  const url = new URL(`${BACKEND}${uri}`)
  if (params) {
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]))
  }
  if (body) {
    init.body = JSON.stringify(body);
  }
  const result = await fetch(url.toString(), init)
  return result
}
