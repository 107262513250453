
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue']
})
export default class ThreeStateToggle extends Vue {

  modelValue: boolean

  one:Element
  two:Element
  three:Element

  mounted() {
    this.one = this.$refs.one as Element
    this.two = this.$refs.two as Element
    this.three = this.$refs.three as Element

    if (this.modelValue === undefined) {
      this.toggle2()
    } else if (this.modelValue) {
      this.toggle3()
    } else {
      this.toggle1()
    }
  }

  toggle1() {
    this.one.classList.add("opaque")
    this.one.classList.remove("transparent")
    this.two.classList.add("transparent")
    this.two.classList.remove("opaque")
    this.three.classList.add("transparent")
    this.three.classList.remove("opaque")
  }

  toggle2() {
    this.two.classList.add("opaque")
    this.two.classList.remove("transparent")
    this.one.classList.add("transparent")
    this.one.classList.remove("opaque")
    this.three.classList.add("transparent")
    this.three.classList.remove("opaque")
  }

  toggle3() {
    this.three.classList.add("opaque")
    this.three.classList.remove("transparent")
    this.two.classList.add("transparent")
    this.two.classList.remove("opaque")
    this.one.classList.add("transparent")
    this.one.classList.remove("opaque")
  }

  toggle(side: number) {
    if (side === 1) {
      this.toggle1();
      this.$emit('update:modelValue', false)
    } else if (side === 2) {
      this.toggle2();
      this.$emit('update:modelValue', undefined)
    } else if (side === 3) {
      this.toggle3();
      this.$emit('update:modelValue', true)
    }
  }
}
