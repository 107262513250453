import authService from '@/auth'

import config from '@/Config'
const BACKEND = config.getBackend();

export default async (uri: string, body: FormData, progressCallback:(pe:ProgressEvent<XMLHttpRequestEventTarget>) => void):Promise<XMLHttpRequest> => {
  let user = await authService.getUser()
  if (user === null) {
    throw new Error('user not logged in')
  }
  if (user.expired) {
    console.warn("Token expiré on en demande un nouveau")
    user = await authService.renewToken()
  }

  const url = new URL(`${BACKEND}${uri}`)

  const request = new XMLHttpRequest();
  request.open('POST', url.toString());
  request.setRequestHeader('Authorization', 'Bearer ' + user!.access_token)

  request.upload.addEventListener('progress', progressCallback);

  const result:Promise<XMLHttpRequest> = new Promise<XMLHttpRequest>((resolve) => {
    request.addEventListener('load', (e) => {
      resolve(request)
    });
  });

  request.send(body);

  return result;
}
