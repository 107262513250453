/* eslint-disable camelcase */
import { OIDCAuthService } from './OIDCAuthService'
import { ImagesolUser } from './ImagesolUser'

export interface AuthService {
  getUser: () => Promise<ImagesolUser | null>
  getAccountUrl: () => string
  login: (currentPath:string) => Promise<any>
  loginCallback: () => Promise<ImagesolUser>
  renewToken: () => Promise<ImagesolUser | null>
  logout: () => Promise<any>
}

const instance:AuthService = new OIDCAuthService()

export default instance
