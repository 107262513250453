import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "group-title-text" }
const _hoisted_3 = { class: "group-content" }
const _hoisted_4 = { class: "slot-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", {
      class: "group-title",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleCollapse()))
    }, [
      _createVNode(_component_Icon, {
        name: _ctx.collapsed?'chevron_right':'expand_more'
      }, null, 8, ["name"]),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.titleKey)), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ], 512), [
        [_vShow, !_ctx.collapsed]
      ])
    ])
  ]))
}