
import { Options, Vue } from 'vue-class-component'

import { success, error } from '@/Toasts'

import contributionService from '@/services/ContributionService'

import { MetadataCollaborater } from '@/model/BackendDTOs'

@Options({
  props: {
    metadataId: String
  }
})
export default class AddCollaborater extends Vue {

  metadataId:string;
  list:any[] = [];

  getAsyncData(name:string) {
    this.list = [];
    if (name && name.length >= 2) {
        contributionService.searchCollaborater(name, this.metadataId)
            .then((data:MetadataCollaborater[]) => {
                if (data) {
                    console.log(`${data.length} collaborateurs potentiels trouvés`, data);
                    this.list = data;
                    this.list.forEach((d) => {
                        // 03/02/2022 Peut-être que ça devrait être en fonction de la langue ?
                        d.fullName = d.firstName + ' ' + d.lastName;
                    });
                }
            });
    }
  }

  collaboraterSelected(selected:MetadataCollaborater) {
    contributionService.addCollaborater(selected.userId, this.metadataId)
    .then(
        () => {
            success(this.$oruga, this.$t('collaboraters.added'));
            const model = {
                userId: selected.userId,
                firstName: selected.firstName,
                lastName: selected.lastName
            }
            this.$emit('added', model);
        },
        (eee) => {
            console.error('Unable to add collaborater', eee);
            error(this.$oruga, `Unable to add collaborater: ${eee}`);
        });
  }
}
