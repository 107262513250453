import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "upload-modal" }
const _hoisted_2 = { class: "upload-title" }
const _hoisted_3 = { class: "uploads-pending" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadRow = _resolveComponent("UploadRow")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('contribute.upload.inProgress')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploads, (u) => {
        return (_openBlock(), _createBlock(_component_UploadRow, {
          key: u.contributionId,
          contributionTitle: u.contributionTitle,
          percent: u.percent,
          complete: u.complete
        }, null, 8, ["contributionTitle", "percent", "complete"]))
      }), 128))
    ])
  ], 512)), [
    [_vShow, _ctx.uploads.length > 0]
  ])
}