
import { Options, Vue } from 'vue-class-component'

import Icon from '@/components/Icon.vue'

@Options({
  props: {
    mandatory: Boolean,
    label: String
  },
  components: {
    Icon
  }
})
export default class FormGroup extends Vue {
    mandatory: boolean;
    collapsed = false;
    label:string|null;

    titleKey = ''

    created() {
        if (this.label) {
            this.titleKey = this.label
        } else if (this.mandatory) {
            this.titleKey = "forms.mandatory"
        } else {
            this.titleKey = "forms.additionalFields"
        }
    }

    toggleCollapse() {
        this.collapsed = !this.collapsed;
    }
}
