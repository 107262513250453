
import { Options, Vue } from 'vue-class-component'

import authService from '@/auth'
import validate from '@/validate'
import { success, warning, error } from '@/Toasts'

import contributionService from '@/services/ContributionService'
import ImagesolError from '@/model/ImagesolError'

import Icon from '@/components/Icon.vue'
import Tag from '@/components/Tag.vue'
import FormSection from '@/components/FormSection.vue'
import FormGroup from '@/components/FormGroup.vue'
import ContributeSteps from '@/components/ContributeSteps.vue'
import ContributeSkeleton from '@/components/ContributeSkeleton.vue'

import TimeSeriesUpdateConflict from '@/components/TimeSeriesUpdateConflict.vue'

import { ImageMetadata, ImageAdministrativeData, TimeSeriesUpdateMethod } from '@/model/BackendDTOs'

import * as yup from 'yup';
import { BaseSchema } from 'yup';

@Options({
  props: {
    id: String
  },
  components: {
    ContributeSteps,
    ContributeSkeleton,
    FormSection,
    FormGroup,
    Icon,
    Tag,
    TimeSeriesUpdateConflict
  }
})
export default class Contribute5Administrative extends Vue {

  id:string;

  authenticated = false
  savePending = false

  errors:{ [index: string]: string } = {};

  metadata:ImageMetadata|null = null;
  model:ImageAdministrativeData|null = null;

  timeSeriesConflictModalActive = false;
  timeSeriesConflictFields:string[]|null = null;

  validations:{ [index: string]: BaseSchema } = {
  //  producer: yup.string().required('mandatory'),
  //  distributor: yup.string().required('mandatory'),
  //  contact: yup.string().required('mandatory')
    contactEmail: yup.string().email().required('mandatory')
  };

  created() {
    authService.getUser().then(u => {
      if (u) {
        this.authenticated = true
        contributionService.loadMetadata(this.id)
          .then(
            (data:ImageMetadata) => {
                this.metadata = data;
                this.model = this.metadata.imageAdministrativeData;
            },
            (e:Error) => {
              error(this.$oruga, e.message)
            }
          )

      } else {
        warning(this.$oruga, this.$t('contribute.step1.notAuthenticated'))
        this.$router.push('/')
      }
    });
  }

  cancel() {
    if (this.metadata?.compliant) {
      if (window.confirm(this.$t('contribute.confirmCancel'))) {
        this.$router.push('/')
      }
    } else {
      if (window.confirm(this.$t('contribute.confirmCancelAndDelete'))) {
        contributionService.deleteMetadata(this.id)
          .then(
            () => {
              success(this.$oruga, this.$t('contribute.dropped'));
              this.$router.push('/')
            },
            (e:Error) => {
              error(this.$oruga, "Impossible de supprimer votre contribution: " + e.message)
            }
          )
      }
    }
  }

  previous() {
    this.$router.push(`/contribute/${this.id}/instructive`)
  }

  saveModel(newDeposit:boolean) {
    this.save0(newDeposit);
  }

  saveOnlyContribution() {
    this.timeSeriesConflictModalActive = false;
    this.save0(false, 'CONTRIBUTION_ONLY');
  }

  saveSpreadToTimeSeries() {
    this.timeSeriesConflictModalActive = false;
    this.save0(false, 'TIME_SERIES');
  }

  save0(newDeposit:boolean, tsum?:TimeSeriesUpdateMethod) {
    this.errors = validate(this.model, this.validations, this.$t);
    if (Object.keys(this.errors).length === 0) {
      this.savePending = true;
      contributionService.saveImageAdministrativeData(this.id, this.model!, tsum)
        .then(
          () => {
            this.savePending = false;
            if (this.metadata?.compliant) {
              success(this.$oruga, this.$t('contribute.updateComplete'))
            } else {
              success(this.$oruga, this.$t('contribute.complete'))
            }
            if (newDeposit) {
              this.$router.push('/contribute')
            } else {
              this.$router.push(`/read/${this.id}`)
            }
          },
          (e:ImagesolError) => {
            this.savePending = false;
            if (e.type && e.type === 'TimeSeriesUpdateDetectedException') {
              this.timeSeriesConflictFields = e.tsuFields!;
              this.timeSeriesConflictModalActive = true;
            } else {
              error(this.$oruga, "Une erreur est survenue pendant la mise à jour de la contribution : " + e.type)
            }
          }
        )
    } else {
      document.getElementById("formTop")?.scrollIntoView({
        behavior: "smooth"
      })
    }
  }

}
