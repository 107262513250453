

import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    label: String,
    labelPrefix: String,
    value: String,
    translatePrefix: String,
    formatter: Function,
    url: Boolean
  }
})
export default class MetadataRow extends Vue {
}
