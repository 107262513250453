import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "contribute" }
const _hoisted_2 = { class: "page-header big" }
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = {
  key: 1,
  class: "columns",
  id: "formTop"
}
const _hoisted_5 = { class: "column" }
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "column" }
const _hoisted_9 = ["value"]
const _hoisted_10 = ["value"]
const _hoisted_11 = ["value"]
const _hoisted_12 = ["value"]
const _hoisted_13 = {
  key: 2,
  class: "contribute-buttons"
}
const _hoisted_14 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ContributeSteps = _resolveComponent("ContributeSteps")!
  const _component_ContributeSkeleton = _resolveComponent("ContributeSkeleton")!
  const _component_o_input = _resolveComponent("o-input")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_o_select = _resolveComponent("o-select")!
  const _component_FormSection = _resolveComponent("FormSection")!
  const _component_o_datetimepicker = _resolveComponent("o-datetimepicker")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TimeSeriesUpdateConflict = _resolveComponent("TimeSeriesUpdateConflict")!
  const _component_o_modal = _resolveComponent("o-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.metadata)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.metadata.compliant ? _ctx.$t("contribute.updateTitle") : _ctx.$t("contribute.title")), 1))
        : _createCommentVNode("", true),
      (_ctx.metadata)
        ? (_openBlock(), _createBlock(_component_Tag, {
            key: 1,
            text: _ctx.metadata.imageBootstrap.summary
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ContributeSteps, {
      step: 3,
      id: _ctx.id,
      compliant: _ctx.metadata?.compliant
    }, null, 8, ["id", "compliant"]),
    (!_ctx.model1)
      ? (_openBlock(), _createBlock(_component_ContributeSkeleton, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.model1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_FormSection, { title: "contribute.step3.sampleSection" }, {
              default: _withCtx(() => [
                _createVNode(_component_FormGroup, { label: "contribute.step3.externalSourceBaseSection" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.externalSourceBase'),
                      variant: _ctx.errors.externalSourceBase ? 'danger':'',
                      message: _ctx.errors.externalSourceBase
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model1.externalSourceBase,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model1.externalSourceBase) = $event)),
                          expanded: ""
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_Tooltip, {
                          label: _ctx.$t('contribute.step3.externalSourceBaseTooltip')
                        }, null, 8, ["label"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.externalEntryName'),
                      variant: _ctx.errors.externalEntryName ? 'danger':'',
                      message: _ctx.errors.externalEntryName
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model1.externalEntryName,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model1.externalEntryName) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.externalSampleId'),
                      variant: _ctx.errors.externalSampleId ? 'danger':'',
                      message: _ctx.errors.externalSampleId
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model1.externalSampleId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model1.externalSampleId) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.externalComment'),
                      variant: _ctx.errors.externalComment ? 'danger':'',
                      message: _ctx.errors.externalComment
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model1.externalComment,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model1.externalComment) = $event)),
                          type: "textarea"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormGroup, { label: "contribute.step3.type" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.type'),
                      variant: _ctx.errors.type ? 'danger':'',
                      message: _ctx.errors.type,
                      class: _normalizeClass('mandatory_' + _ctx.$i18n.locale)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_select, {
                          modelValue: _ctx.model1.type,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model1.type) = $event)),
                          expanded: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sampleTypes, (d) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: d,
                                value: d
                              }, _toDisplayString(_ctx.$t('enums.SampleType.' + d)), 9, _hoisted_6))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.sampleTypeAdditionalInformation'),
                      variant: _ctx.errors.sampleTypeAdditionalInformation ? 'danger':'',
                      message: _ctx.errors.sampleTypeAdditionalInformation
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model1.sampleTypeAdditionalInformation,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model1.sampleTypeAdditionalInformation) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormGroup, { label: "contribute.step3.shape" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.shape'),
                      variant: _ctx.errors.shape ? 'danger':'',
                      message: _ctx.errors.shape,
                      class: _normalizeClass('mandatory_' + _ctx.$i18n.locale)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_select, {
                          modelValue: _ctx.model1.shape,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model1.shape) = $event)),
                          expanded: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shapes, (d) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: d,
                                value: d
                              }, _toDisplayString(_ctx.$t('enums.Shape.' + d)), 9, _hoisted_7))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    _createVNode(_component_o_field, {
                      grouped: "",
                      label: _ctx.$t('contribute.step3.sampleSize'),
                      class: _normalizeClass('mandatory_' + _ctx.$i18n.locale)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_field, {
                          label: _ctx.$t('contribute.step3.sampleSizeX'),
                          horizontal: "",
                          variant: _ctx.errors.sampleSizeX ? 'danger':'',
                          message: _ctx.errors.sampleSizeX
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_input, {
                              modelValue: _ctx.model1.sampleSizeX,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model1.sampleSizeX) = $event)),
                              type: "number",
                              min: "0"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message"]),
                        _createVNode(_component_o_field, {
                          label: _ctx.$t('contribute.step3.sampleSizeY'),
                          horizontal: "",
                          variant: _ctx.errors.sampleSizeY ? 'danger':'',
                          message: _ctx.errors.sampleSizeY
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_input, {
                              modelValue: _ctx.model1.sampleSizeY,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model1.sampleSizeY) = $event)),
                              type: "number",
                              min: "0"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message"]),
                        _createVNode(_component_o_field, {
                          label: _ctx.$t('contribute.step3.sampleSizeZ'),
                          horizontal: "",
                          variant: _ctx.errors.sampleSizeZ ? 'danger':'',
                          message: _ctx.errors.sampleSizeZ
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_input, {
                              modelValue: _ctx.model1.sampleSizeZ,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model1.sampleSizeZ) = $event)),
                              disabled: _ctx.model1.shape === 'CYLINDER',
                              type: "number",
                              min: "0"
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message"])
                      ]),
                      _: 1
                    }, 8, ["label", "class"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_FormSection, {
              class: "column",
              title: "contribute.step3.samplingContextSection"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FormGroup, { label: "contribute.step3.sampling" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.samplingDateTime'),
                      variant: _ctx.errors.samplingDateTime ? 'danger':'',
                      message: _ctx.errors.samplingDateTime,
                      class: _normalizeClass(_ctx.model1.type === 'NATURAL' ? ('mandatory_' + _ctx.$i18n.locale) : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_datetimepicker, {
                          modelValue: _ctx.model1.samplingDateTime,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model1.samplingDateTime) = $event)),
                          locale: _ctx.$i18n.locale,
                          datepicker: { showWeekNumber: false },
                          timepicker: { enableSeconds: false, hourFormat: '24' }
                        }, null, 8, ["modelValue", "locale"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    _createVNode(_component_o_field, {
                      grouped: "",
                      label: _ctx.$t('contribute.step3.location'),
                      class: _normalizeClass(_ctx.model1.type === 'NATURAL' ? ('mandatory_' + _ctx.$i18n.locale) : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_field, {
                          label: _ctx.$t('contribute.step3.country'),
                          variant: _ctx.errors.region ? 'danger':'',
                          message: _ctx.errors.region,
                          class: _normalizeClass(_ctx.model1.type === 'NATURAL' ? ('mandatory_' + _ctx.$i18n.locale) : '')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_select, {
                              modelValue: _ctx.country,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.country) = $event)),
                              expanded: ""
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (d) => {
                                  return (_openBlock(), _createElementBlock("option", {
                                    key: d,
                                    value: d
                                  }, _toDisplayString(d['country_' + _ctx.$i18n.locale]), 9, _hoisted_9))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message", "class"]),
                        _createVNode(_component_o_field, {
                          label: _ctx.$t('contribute.step3.region'),
                          variant: _ctx.errors.region ? 'danger':'',
                          message: _ctx.errors.region,
                          class: _normalizeClass(_ctx.model1.type === 'NATURAL' ? ('mandatory_' + _ctx.$i18n.locale) : '')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_o_select, {
                              modelValue: _ctx.model1.region,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model1.region) = $event)),
                              expanded: ""
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.regions, (d) => {
                                  return (_openBlock(), _createElementBlock("option", {
                                    key: d,
                                    value: d
                                  }, _toDisplayString(d['region_' + _ctx.$i18n.locale]), 9, _hoisted_10))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label", "variant", "message", "class"])
                      ]),
                      _: 1
                    }, 8, ["label", "class"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.lte'),
                      variant: _ctx.errors.lte ? 'danger':'',
                      message: _ctx.errors.lte
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model1.lte,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model1.lte) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormGroup, { label: "contribute.step3.taking" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.samplingMethod'),
                      variant: _ctx.errors.samplingMethod ? 'danger':'',
                      message: _ctx.errors.samplingMethod
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          modelValue: _ctx.model1.samplingMethod,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model1.samplingMethod) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.samplingGuidance'),
                      variant: _ctx.errors.samplingGuidance ? 'danger':'',
                      message: _ctx.errors.samplingGuidance,
                      class: _normalizeClass(_ctx.model1.type === 'NATURAL' ? ('mandatory_' + _ctx.$i18n.locale) : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_select, {
                          modelValue: _ctx.model1.samplingGuidance,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model1.samplingGuidance) = $event)),
                          expanded: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.samplingGuidances, (d) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: d,
                                value: d
                              }, _toDisplayString(_ctx.$t('enums.SamplingGuidance.' + d)), 9, _hoisted_11))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message", "class"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.samplingRowType'),
                      variant: _ctx.errors.samplingRowType ? 'danger':'',
                      message: _ctx.errors.samplingRowType
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_select, {
                          modelValue: _ctx.model1.samplingRowType,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model1.samplingRowType) = $event)),
                          expanded: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.samplingRowTypes, (d) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: d,
                                value: d
                              }, _toDisplayString(_ctx.$t('enums.SamplingRowType.' + d)), 9, _hoisted_12))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"]),
                    _createVNode(_component_o_field, {
                      label: _ctx.$t('contribute.step3.sampleComment'),
                      variant: _ctx.errors.sampleComment ? 'danger':'',
                      message: _ctx.errors.sampleComment
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_input, {
                          type: "textarea",
                          modelValue: _ctx.model1.sampleComment,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model1.sampleComment) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "variant", "message"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.model1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createVNode(_component_o_button, {
            onClick: _ctx.cancel,
            inverted: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('forms.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_o_button, {
            onClick: _ctx.previous,
            outlined: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('forms.previous')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_o_button, {
            onClick: _ctx.saveModel1,
            disabled: !_ctx.authenticated || _ctx.savePending
          }, {
            default: _withCtx(() => [
              (!_ctx.savePending)
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('forms.next')), 1))
                : _createCommentVNode("", true),
              (_ctx.savePending)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 1,
                    name: "refresh",
                    rotate: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_o_modal, {
      active: _ctx.timeSeriesConflictModalActive,
      "onUpdate:active": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.timeSeriesConflictModalActive) = $event)),
      scroll: "clip"
    }, {
      default: _withCtx(() => [
        (_ctx.timeSeriesConflictModalActive)
          ? (_openBlock(), _createBlock(_component_TimeSeriesUpdateConflict, {
              key: 0,
              fields: _ctx.timeSeriesConflictFields,
              prefix: 'contribute.step3',
              onCancel: _cache[18] || (_cache[18] = ($event: any) => (_ctx.timeSeriesConflictModalActive = false)),
              onOnlyContribution: _ctx.saveOnlyContribution,
              onSpreadToTimeSeries: _ctx.saveSpreadToTimeSeries
            }, null, 8, ["fields", "prefix", "onOnlyContribution", "onSpreadToTimeSeries"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}