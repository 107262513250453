import { Log, UserManager, UserManagerSettings, WebStorageStateStore, SigninRedirectArgs } from 'oidc-client-ts'

import config from '@/Config'

import { AuthService } from './'
import { ImagesolUser } from './ImagesolUser'

const AUTHORITY = config.getAuthority();
const CLIENT_ID = config.getClientId();

export class OIDCAuthService implements AuthService {
  public userManager: UserManager

  constructor() {
    const settings:UserManagerSettings = {
      authority: AUTHORITY,
      client_id: CLIENT_ID,
      redirect_uri: `${document.location.origin}/login-callback`,
      post_logout_redirect_uri: `${document.location.origin}`,
      response_type: 'code',
      scope: 'openid profile email',
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      automaticSilentRenew: true
    }
    this.userManager = new UserManager(settings)
    this.userManager.events.addUserSignedOut(async () => {
      console.log('user signed out...')
      this.logout()
    })
    this.userManager.clearStaleState();

    Log.setLogger(console);
    Log.setLevel(Log.WARN);
  }

  public getUser():Promise<ImagesolUser | null> {
    return this.userManager.getUser()
  }

  public getAccountUrl() {
    const currentLocation = encodeURI(document.location.href);
    const result = `${AUTHORITY}/account?referrer=${CLIENT_ID}&referrer_uri=${currentLocation}`;
    return result;
  }

  public login(currentPath:string) {
    const args:SigninRedirectArgs = {
      state: { pathBeforeLogin: currentPath }
    };
    return this.userManager.signinRedirect(args);
  }

  public loginCallback() {
    return this.userManager.signinRedirectCallback();
  }

  public renewToken():Promise<ImagesolUser | null> {
    return this.userManager.signinSilent()
  }

  public logout() {
    return this.userManager.signoutRedirect()
  }
}
