
import { Options, Vue } from 'vue-class-component'

import xhrPostZipFile from '@/xhrPostZipFile'

import authService from '@/auth'
import validate from '@/validate'
import { success, warning, error } from '@/Toasts'

import contributionService from '@/services/ContributionService'
import ImagesolError from '@/model/ImagesolError'

import Tag from '@/components/Tag.vue'
import Icon from '@/components/Icon.vue'
import FormSection from '@/components/FormSection.vue'
import FormGroup from '@/components/FormGroup.vue'
import ContributeSteps from '@/components/ContributeSteps.vue'
import Tooltip from '@/components/Tooltip.vue'

import UploadRow from '@/components/UploadRow.vue'

import TimeSeriesUpdateConflict from '@/components/TimeSeriesUpdateConflict.vue'
import TimeSeriesInfo from '@/components/TimeSeriesInfo.vue'

import { ImageBootstrap, ImageMetadata, ImageMetadataFile, TimeSeriesUpdateMethod } from '@/model/BackendDTOs'
import StartUploadEvent from '@/model/StartUploadEvent'

import * as yup from 'yup';
import { BaseSchema } from 'yup';

import events from '@/events'

@Options({
  props: {
    id: String
  },
  components: {
    ContributeSteps,
    FormSection,
    FormGroup,
    Icon,
    Tag,
    TimeSeriesUpdateConflict,
    TimeSeriesInfo,
    Tooltip,
    UploadRow
  }
})
export default class Contribute1Bootstrap extends Vue {

  id: string | undefined;
  copiedId: string | undefined;
  creationComplete = false;

  authenticated = false
  savePending = false

  file:File|null = null;
  summary:string|null = null;
  description:string|null = null;

  fileOnServer:ImageMetadataFile|null = null;

  timeSeriesType: "NEW" | "EXISTING" | "NO" | null = null;

  timeSeriesId: string | null = null;
  timeSeriesFileInfoModalActive = false;

  uploadPercent: number | null = null;
  uploadStatus: "NONE" | "UPLOADING" | "RESULTS" = "NONE";
  uploadResponse: { type: string, error: string, cause: string } | null = null;

  errors:{ [index: string]: string } = {};

  timeSeriesConflictModalActive = false;
  timeSeriesConflictFields:string[]|null = null;

  validations:{ [index: string]: BaseSchema } = {
    summary: yup.string().nullable().trim().required('mandatory').min(5, 'Le résumé ne peut faire moins de 5 caractères'),
    description: yup.string().nullable().trim().required('mandatory')
  };

  validationsTimeSeries:{ [index: string]: BaseSchema } = {
    timeSeriesId: yup.string().required('mandatory')
  };

  created() {
    this.copiedId = this.id;
    authService.getUser().then(u => {
      if (u) {
        this.authenticated = true

        if (this.copiedId) {
          contributionService.loadMetadata(this.copiedId)
            .then(
              (data:ImageMetadata) => {
                this.creationComplete = data.compliant;
                this.summary = data.imageBootstrap.summary;
                this.description = data.imageBootstrap.description || null;
                this.fileOnServer = data.file || null;
                this.timeSeriesId = data.imageBootstrap.timeSeriesId || null;
                this.timeSeriesType = data.imageBootstrap.timeSeriesId ? "EXISTING" : "NO";
              },
              (e:Error) => {
                error(this.$oruga, e.message)
              }
            )
        }
      } else {
        warning(this.$oruga, this.$t('contribute.step1.notAuthenticated'))
      }
    });
  }

  cancel() {
    if (this.copiedId) {
      if (this.creationComplete) {
        if (window.confirm(this.$t('contribute.confirmCancel'))) {
          this.$router.push('/')
        }
      } else {
        if (window.confirm(this.$t('contribute.confirmCancelAndDelete'))) {
          contributionService.deleteMetadata(this.copiedId)
            .then(
              () => {
                success(this.$oruga, this.$t('contribute.dropped'));
                this.$router.push('/')
              },
              (e:Error) => {
                error(this.$oruga, "Impossible de supprimer votre contribution: " + e.message)
              }
            )
        }
      }
    } else {
      this.$router.push('/')
    }
  }

  save() {
    this.save0();
  }

  saveOnlyContribution() {
    this.timeSeriesConflictModalActive = false;
    this.save0('CONTRIBUTION_ONLY');
  }

  saveSpreadToTimeSeries() {
    this.timeSeriesConflictModalActive = false;
    this.save0('TIME_SERIES');
  }

  save0(tsum?:TimeSeriesUpdateMethod) {
    const model:ImageBootstrap = {
      compliant: false,
      summary: this.summary!,
      description: this.description!,
      timeSeriesId: this.timeSeriesId!,
      timeSeries: this.timeSeriesType !== "NO"
    }
    let validations = this.validations
    if (this.timeSeriesType === "EXISTING") {
      validations = { ...validations, ...this.validationsTimeSeries };
    }
    this.errors = validate(model, this.validations, this.$t);
    if ((!this.fileOnServer || this.fileOnServer == null) && (!this.file || this.file == null)) {
      this.errors.file = this.$t('forms.errors.mandatory')
    }
    if (this.copiedId) {
      if (Object.keys(this.errors).length === 0) {
        this.savePending = true;
        contributionService.saveImageBootstrap(this.copiedId, model, tsum)
          .then(
            () => {
              this.savePending = false;
              if ((!this.fileOnServer || this.fileOnServer == null) && this.file) {
                this.startFileUpload(this.copiedId!)
              } else {
                this.$router.push(`/contribute/${this.copiedId}/technical`)
              }
            },
            (e:ImagesolError) => {
              this.savePending = false;
              if (e.type && e.type === 'TimeSeriesUpdateDetectedException') {
                this.timeSeriesConflictFields = e.tsuFields!;
                this.timeSeriesConflictModalActive = true;
              } else {
                error(this.$oruga, "Une erreur est survenue pendant la mise à jour de la contribution : " + e.type)
              }
            }
          )

      }
    } else {
      if (Object.keys(this.errors).length === 0) {
        this.savePending = true;
        contributionService.createImageBootstrap(model)
          .then(
            (newId) => {
              this.savePending = false;
              this.copiedId = newId;
              this.startFileUpload(newId);
            },
            (e:Error) => {
              this.savePending = false;
              error(this.$oruga, "Une erreur est survenue pendant la création de la contribution : " + e.message)
            }
          )
      }
    }
  }

  startFileUpload(id:string) {
    if (this.file!.name.endsWith(".zip")) {
      if (this.uploadStatus === "RESULTS" && this.uploadResponse === null) {
        this.$router.push(`/contribute/${id}/technical`)
      } else {
        this.uploadZip(id);
      }
    } else {
      const uploadFile:StartUploadEvent = {
        contributionId: id,
        contributionTitle: this.summary!,
        file: this.file!
      }
      events.emit('start-upload', uploadFile)
      this.$router.push(`/contribute/${id}/technical`)
    }
  }

  uploadZip(id: string) {
    console.log("Start uploading zip")

    const watchProgress = (e:ProgressEvent<XMLHttpRequestEventTarget>) => {
        const percentCompleted = (e.loaded / e.total) * 100;
        this.uploadPercent = percentCompleted;
        this.$forceUpdate();
    }

    const data = new FormData()
    data.append('uploadedFile', this.file!)

    this.uploadStatus = "UPLOADING"

    xhrPostZipFile(`/v1/imagesol/contribution/${id}/zip`, data, watchProgress)
      .then(
        (request) => {
          this.uploadStatus = "RESULTS"
          if (request.status === 200 || request.status === 204) {
            if (request.response) {
              this.timeSeriesId = request.response
            }
            this.$forceUpdate();
          } else {
            this.uploadResponse = JSON.parse(request.response);
            this.$forceUpdate();
          }
        }, () => {
          error(this.$oruga, "Une erreur est survenue pendant le téléversement du fichier")
        });
  }

  returnToForm() {
    if (this.copiedId) {
      contributionService.deleteMetadata(this.copiedId)
      this.copiedId = undefined;
    }
    this.uploadStatus = "NONE";
    this.uploadResponse = null;
    this.uploadPercent = null;
    this.fileOnServer = null;
  }

  formatFileSize(octets:number):string {
    if (octets < 1024) {
      return octets + " o"
    }
    const kiloOctets = octets / 1024;
    if (kiloOctets < 1024) {
      return (Math.floor(kiloOctets * 10) / 10) + " Ko"
    }

    const megaOctets = kiloOctets / 1024;
    if (megaOctets < 1024) {
      return (Math.floor(megaOctets * 10) / 10) + " Mo"
    }

    const gigaOctets = megaOctets / 1024;
    return (Math.floor(gigaOctets * 10) / 10) + " Go"
  }

}
